import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tooltip,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { logout } from '../../actions/auth';
import { setDIPBadgeNumbers } from '../../actions/clients';
import { SET_MESSAGE } from '../../actions/types';
import {
  ArrowDownward,
  ArrowUpward,
  FileCopy as FileCopyIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import moment from 'moment';

import { isAdmin } from '../../common/utils/RoleUtils';

import { isValidDate } from '../../common/utils/ValidationUtils.js';

import { clientService, providerService } from '../../services';
import { useTheme, useMediaQuery } from '@mui/material';
import styles from '../../styles/DIP.module.css';

const sampleNote =
  "Client has not been responsive since ____. A discharge letter was mailed to client on ____ (Discharge letter is in document section of chart) Client has not contacted this writer to date and consequently client's chart is being changed to inactive status.";

const isDatePassedTenDays = (selectedDate) => {
  if (!selectedDate) {
    return true;
  }
  const currentDate = new Date();
  const selectedDateObj = new Date(selectedDate);
  const timeDifference = currentDate - selectedDateObj;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference >= 10;
};

export default function DIP() {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState('Provider');
  const [radioSelections, setRadioSelections] = useState({});
  const [sortDirection, setSortDirection] = useState('asc');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedProvider, setSelectedProvider] = useState({});
  const [displayedClients, setDisplayedClients] = useState(clients);
  const [selectedStatus] = useState(null);
  const [confirmationModalOpen] = useState(false);
  const [confirmedSelections, setConfirmedSelections] = useState({});
  // const [isAllConfirmedModalVisible, setIsAllConfirmedModalVisible] =
  //   useState(false);
  const [specificConfirmationModalOpen, setSpecificConfirmationModalOpen] =
    useState(false);
  const [successMessage] = useState('');
  const [modalMessageType, setModalMessageType] = useState('');
  const [dateSelections, setDateSelections] = useState({});
  const [users] = React.useState([]);
  const [, setSelectedUser] = React.useState();
  const [, setSelectedUserDetail] = React.useState();
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [specificRadioSelection, setSpecificRadioSelection] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [successMessageTrigger, setSuccessMessageTrigger] = useState(false);
  const [clientProviderId, setClientProviderId] = useState();
  // The above state contains the providerId for a client. This state has been made to cater Admin adding Date
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user: currentUser } = useSelector((state) => state.auth);

  const sortClients = (clients) => {
    if (!Array.isArray(clients)) return [];

    return [...clients].sort((a, b) => {
      let primaryValA, primaryValB, secondaryValA, secondaryValB;

      switch (sortColumn) {
        case 'Provider':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2]; // Sorting by last name
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          secondaryValA = `${a.LASTNAME} ${a.FIRSTNAME}`;
          secondaryValB = `${b.LASTNAME} ${b.FIRSTNAME}`;
          break;
        case 'Name':
          primaryValA = a.LASTNAME;
          primaryValB = b.LASTNAME;
          secondaryValA = a.FIRSTNAME;
          secondaryValB = b.FIRSTNAME;
          break;
        case 'Status':
          primaryValA = a.STATUS;
          primaryValB = b.STATUS;
          break;
        case 'Therapist':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2];
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          break;
        default:
          return 0;
      }

      if (primaryValA < primaryValB) return sortDirection === 'asc' ? -1 : 1;
      if (primaryValA > primaryValB) return sortDirection === 'asc' ? 1 : -1;

      // Secondary sort logic (for Provider and Name)
      if (secondaryValA < secondaryValB) return -1;
      if (secondaryValA > secondaryValB) return 1;

      return 0;
    });
  };

  const calculateBadgeNumbers = () => {
    const totalRows = clients?.length; // This is the number for the black badge.
    const twoWeeksAgo = moment().subtract(10, 'days');
    const rowsOverTwoWeeks = clients?.filter((client) => {
      const letterMailedDate = moment.utc(client.AWOL_DATE_MAILED);
      return letterMailedDate.isSameOrBefore(twoWeeksAgo, 'day');
    }).length; // This is the number for the red badge.

    dispatch(setDIPBadgeNumbers(totalRows, rowsOverTwoWeeks));
  };

  const toggleClientDetail = (clientId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [clientId]: !prevExpandedRows[clientId],
    }));
  };

  const handleRadioChange = (clientId, selection, providerId) => {
    setSpecificRadioSelection(selection);
    setRadioSelections({
      [clientId]: selection,
    });
    setSelectedClientId(clientId); // Keep track of the selected client ID

    // Open the specific confirmation modal for these columns
    setSpecificConfirmationModalOpen(true);
    setModalMessageType(selection);
    setClientProviderId(providerId);
    console.log('specificConfirmationModalOpen set to true');
  };

  const handleProviderChange = (event) => {
    const providerId = Number(event.target.value); // Convert to Number if your IDs are numerical
    const provider = providers.find((p) => p.id === providerId);
    setSelectedProvider(provider || { id: 1001 });
  };

  const handleDateChange = (dataObj, newDate) => {
    newDate = moment(newDate).format('YYYY-MM-DD');

    // Update the date selection state immediately
    setDateSelections((prev) => ({
      ...prev,
      [dataObj.tamrn]: newDate,
    }));

    setSelectedClientId(dataObj.tamrn); // Update client ID if needed
    setClientProviderId(dataObj.providerId); // ProviderId explicitly here

    if (isValidDate(newDate)) {
      setSpecificConfirmationModalOpen(true); // Trigger modal after delay
    }
  };

  const handleConfirmSubmit = async () => {
    setConfirmedSelections((prev) => ({ ...prev, [selectedClientId]: true }));
    setSpecificConfirmationModalOpen(false);
    setSelectedClientId(null);
    if (
      specificRadioSelection !== 'remained_awol' &&
      specificRadioSelection !== 'returned'
    ) {
      await handleAddAdminNameToClient({
        MRN: Object.keys(dateSelections)[0],
        dateAdded: moment(Object.values(dateSelections)[0]).format(
          'YYYY-MM-DD'
        ), // Format date to 'YYYY-MM-DD'
        adminName: currentUser.username,
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    } else if (specificRadioSelection === 'remained_awol') {
      await clientService.addClientToStillAwol({
        MRN: Object.keys(radioSelections)[0], //MRN
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    } else if (specificRadioSelection === 'returned') {
      await clientService.addClientToReturned({
        MRN: Object.keys(radioSelections)[0], //MRN
        providerId:
          selectedProvider.id !== 1001 ? selectedProvider.id : clientProviderId, // passing providerID to backend
      });
    }
    handleCombinedSubmit(); //Calling it at the end
  };

  const handleCancelConfirmation = () => {
    setRadioSelections((prev) => ({ ...prev, [selectedClientId]: '' }));
    setSpecificConfirmationModalOpen(false);
    setSelectedClientId(null);
  };

  const handleAddAdminNameToClient = async (updatedBody) => {
    await clientService.addAdminNameToClient(updatedBody);
  };

  // useEffect(() => {
  //   if (Object.keys(dateSelections).length !== 0) {
  //     setSpecificConfirmationModalOpen(true);
  //   }
  // }, [dateSelections]);

  useEffect(() => {
    if (isAdmin(currentUser)) {
      if (selectedProvider.id === 1001) {
        setLoading(true); // Start loading only after 'ALL' is selected
        clientService
          .getDIPAllClients() // Method assumed to fetch all clients for admin
          .then((response) => {
            const activeClients = response?.data?.clients;
            setClients(activeClients);
            setDisplayedClients(activeClients);
          })
          .catch((error) => {
            console.error('There was an error fetching all clients:', error);
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false); // Stop loading whether there was an error or not
          });
      } else if (selectedProvider.id) {
        setLoading(true); // Start loading only after a specific provider is selected
        clientService
          .getClientsByProvider(selectedProvider.id)
          .then((response) => {
            const activeAwolClients = response?.data?.clients?.filter(
              (client) =>
                client?.STATUS === 'Active' && client?.AWOL_CLIENT === 1
            );
            setClients(activeAwolClients);
            setDisplayedClients(activeAwolClients);
          })
          .catch((error) => {
            console.error(
              'There was an error fetching the clients for the selected provider:',
              error
            );
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false);
          });
      }
      // Note: No else clause to setLoading(true) here, as we don't want to show loading when nothing is selected
    } else {
      // If a provider is logged in, show loading immediately since their clients are being fetched
      setLoading(true);
      clientService
        .getMyClients() // Adjust according to your method names
        .then((response) => {
          const activeClients = response.data.clients.filter(
            (client) => client.AWOL_CLIENT && client.STATUS === 'Active'
          );
          setClients(activeClients);
          setDisplayedClients(activeClients);
        })
        .catch((error) => {
          console.error('There was an error fetching my clients:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider, successMessageTrigger, currentUser]);

  useEffect(() => {
    // Initialize confirmed selections when displayedClients changes
    const initialConfirmedSelections = displayedClients?.reduce(
      (acc, client) => {
        acc[client.ta_mrn] = false; // Assuming ta_mrn is your unique identifier
        return acc;
      },
      {}
    );

    setConfirmedSelections(initialConfirmedSelections);
  }, [displayedClients]);

  useEffect(() => {
    providerService.getProviders().then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        // Extract last name by finding the word before the comma
        if (providers?.length > 0) {
          providers.sort((a, b) => {
            const lastNameA = (a?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            const lastNameB = (b?.provider_name || '')
              .split(', ')[0]
              .split(' ')
              .slice(-1)[0]
              .toLowerCase();

            return lastNameA.localeCompare(lastNameB);
          });
        }
        setProviders(providers);
      },
      (error) => {
        // Handle any errors here
        console.error('Error fetching providers:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedProvider?.id !== 1001) {
      if (isAdmin(currentUser) && selectedProvider?.id) {
        setLoading(true);
        setClients([]); // Clear out the currently displayed clients
        setDisplayedClients([]); // Clear out the currently displayed clients

        clientService
          .getClientsByProvider(selectedProvider.id)
          .then((response) => {
            const activeAwolClients = response?.data?.clients?.filter(
              (client) =>
                client?.STATUS === 'Active' && client?.AWOL_CLIENT === 1
            );
            setClients(activeAwolClients);
            setDisplayedClients(activeAwolClients);
          })
          .catch((error) => {
            console.error(
              'There was an error fetching the clients for the selected provider:',
              error
            );
            // here have to make user logout of the application
            dispatch({
              type: SET_MESSAGE,
              payload:
                'You have been logged out something went wrong. Please click on login button to login back!',
            });
            dispatch(logout(currentUser));
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // Clear the client list if no provider is selected or if the user is not an admin
        setClients([]);
        setDisplayedClients([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedProvider]);

  useEffect(() => {
    calculateBadgeNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (currentUser.roles.indexOf('ROLE_DOCTOR') > -1) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const providerDetail = await providerService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = enObj.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  useEffect(() => {
    if (!isAdmin(currentUser)) {
      setSelectedProvider({
        ...currentUser,
        provider_name: currentUser.username,
      });
    }
  }, [currentUser]);

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleCombinedSubmit = async () => {
    try {
      // Prepare data for updating new columns in the database.
      // This involves mapping the current selections to a format suitable for the backend API.
      const selectedClient = displayedClients.find(
        (displayedClient) => displayedClient.ta_mrn
      );
      const clientUpdates = Object.entries(radioSelections).map(
        ([clientId, selection]) => {
          return {
            clientId: clientId,
            providerId:
              selectedProvider.id !== 1001
                ? selectedProvider.id
                : clientProviderId,
            establishedClient: selection === 'established',
            futureClient: selection === 'future',
            awolClient: selection === 'awol',
            neverShowedClient: selection === 'never_showed',
            agreedTerminationClient: selection === 'agreed_termination',
            remainedAwolClient: selection === 'remained_awol',
            returnedClient: selection === 'returned',
            letterMailedOn:
              dateSelections[clientId] ||
              selectedClient.AWOL_DATE_MAILED ||
              null,
            changeStatusToInactive: selection === 'agreed_termination',
            // Additional statuses can be added here as needed.
          };
        }
      );

      // Send the updates to the backend using the clientService.
      console.log('Before sending updates to backend');
      await clientService.updateClientColumns(clientUpdates);
      console.log('After sending updates to backend');

      // After successful submission, close the modal and show a success message.
      // setIsAllConfirmedModalVisible(false); // This will close the modal

      setSuccessMessageTrigger(
        (successMessageTrigger) => successMessageTrigger + 1
      ); // Setting true for the above success message trigger so that success message can be displayed
    } catch (error) {
      console.log('Error in handleCombinedSubmit:', error);
      // Handle any errors that occur during the submission process.
      // This could include displaying error messages to the user.
    } finally {
      console.log('Completed handleCombinedSubmit');
      // Any final cleanup can be performed here.
    }
  };

  return (
    <div>
      {isAdmin(currentUser) && (
        <FormControl
          sx={{
            width: isMobile ? '90%' : '20%',
            marginBottom: isMobile ? '0.5rem' : '0',
            marginTop: isMobile ? '1rem' : '0',
            marginLeft: isMobile ? '1.5rem' : '0',
          }}
        >
          <InputLabel id="providerSelectLabel">Please Select</InputLabel>
          <Select
            labelId="providerSelectLabel"
            value={selectedProvider?.id || ''}
            onChange={handleProviderChange}
            label="Please Select"
          >
            <MenuItem value="">Please Select</MenuItem>
            <MenuItem key={1001} value={1001}>
              ALL
            </MenuItem>

            {providers
              .filter(
                (provider) =>
                  provider.provider_name && provider.provider_name.trim() !== ''
              )
              .map((provider) => (
                <MenuItem key={provider.id} value={provider.id}>
                  {provider.provider_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      {loading ? (
        <p>Loading...</p>
      ) : displayedClients.length > 0 ? (
        <>
          {isMobile ? (
            <div className={styles.mobileDipList}>
              {displayedClients.map((client) => (
                <div
                  key={client.ta_mrn}
                  className={styles.mobileDipCard}
                  onClick={() => toggleClientDetail(client.ta_mrn)}
                >
                  <div
                    className={styles.dipClientName}
                  >{`${client.FIRSTNAME} ${client.LASTNAME}`}</div>
                  {expandedRows[client.ta_mrn] && (
                    <div className={styles.dipClientDetails}>
                      {/* Provider Name Column  */}
                      <div className={styles.centeredContent}>
                        <strong>Provider:</strong> {client.PROVIDER}
                      </div>

                      <div className={styles.centeredContent}>
                        <strong>AWOL Date Mailed:</strong>
                        <input
                          type="date"
                          value={
                            dateSelections[client.ta_mrn] ||
                            (client.AWOL_DATE_MAILED
                              ? moment(client.AWOL_DATE_MAILED).format(
                                  'YYYY-MM-DD'
                                )
                              : '')
                          }
                          onChange={(e) =>
                            handleDateChange(client.ta_mrn, e.target.value)
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>

                      <div className={styles.centeredContent}>
                        <strong>Still AWOL:</strong>
                        <Checkbox
                          checked={
                            radioSelections[client.ta_mrn] === 'still_awol'
                          }
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'still_awol')
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                      <div className={styles.centeredContent}>
                        <strong>Client Returned:</strong>
                        <Checkbox
                          checked={
                            radioSelections[client.ta_mrn] === 'returned'
                          }
                          onChange={() =>
                            handleRadioChange(client.ta_mrn, 'returned')
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <table border="1">
              <thead>
                <tr>
                  {/* Name column with sorting */}
                  <th onClick={() => toggleSort('Name')}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ marginRight: '5px' }}>Name</span>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ArrowUpward
                          style={{
                            color:
                              sortColumn === 'Name' && sortDirection === 'asc'
                                ? 'black'
                                : 'lightgray',
                            fontSize: '16px',
                          }}
                        />
                        <ArrowDownward
                          style={{
                            color:
                              sortColumn === 'Name' && sortDirection === 'desc'
                                ? 'black'
                                : 'lightgray',
                            fontSize: '16px',
                          }}
                        />
                      </div>
                    </div>
                  </th>

                  {/* Conditional Column: Provider */}
                  {isAdmin(currentUser) && selectedProvider?.id === 1001 && (
                    <th>Provider</th>
                  )}

                  {/* Letter Mailed On... column */}
                  <th>
                    <div
                      className={styles.headerWithTooltip}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <span style={{ marginRight: '5px' }}>
                        Letter Mailed On...
                      </span>

                      <Tooltip
                        title="Your Client Still Remained AWOL"
                        sx={{ '.MuiTooltip-tooltip': { fontSize: '1.1em' } }}
                      >
                        <IconButton
                          aria-label="help"
                          style={{ position: 'absolute', right: 0, top: 0 }}
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>

                  {/* Admin column */}
                  <th>Admin</th>

                  {/* Still AWOL column */}
                  <th>
                    <div
                      className={styles.headerWithTooltip}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <span style={{ marginRight: '5px' }}>Still AWOL</span>
                      <Tooltip
                        title="Your Client Still Remained AWOL"
                        sx={{ '.MuiTooltip-tooltip': { fontSize: '1.1em' } }}
                      >
                        <IconButton
                          aria-label="help"
                          style={{ position: 'absolute', right: -5, top: 0 }}
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>

                  {/* Client Returned column */}
                  <th>
                    <div
                      className={styles.headerWithTooltip}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <span style={{ marginRight: '5px' }}>
                        Client Returned
                      </span>
                      <Tooltip
                        title="Has Your Client Has Returned"
                        sx={{ '.MuiTooltip-tooltip': { fontSize: '1.1em' } }}
                      >
                        <IconButton
                          aria-label="help"
                          style={{ position: 'absolute', right: 0, top: 0 }}
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortClients(displayedClients).map((client) => (
                  <tr
                    key={client.ta_mrn}
                    className={
                      confirmedSelections[client.ta_mrn] ||
                      ['remained_awol', 'returned'].includes(
                        radioSelections[client.ta_mrn]
                      )
                        ? styles.highlightedRow
                        : ''
                    }
                  >
                    <td style={{ fontSize: '1.2em' }}>
                      {`${client.FIRSTNAME} ${client.LASTNAME}`}
                    </td>
                    {/* Conditional rendering for the Provider data */}
                    {isAdmin(currentUser) && selectedProvider?.id === 1001 && (
                      <td style={{ fontSize: '1.2em' }}>{client.PROVIDER}</td>
                    )}
                    <td className={styles.centeredRadio}>
                      <span>
                        <input
                          type="date"
                          value={
                            dateSelections[client.ta_mrn]
                              ? moment(dateSelections[client.ta_mrn]).format(
                                  'YYYY-MM-DD'
                                )
                              : client.AWOL_DATE_MAILED
                              ? moment(client.AWOL_DATE_MAILED).format(
                                  'YYYY-MM-DD'
                                )
                              : ''
                          }
                          onChange={(event) => {
                            // Pass the formatted date directly to handleDateChange
                            handleDateChange(
                              {
                                tamrn: client.ta_mrn,
                                providerId: client.provider_id,
                              },
                              event.target.value
                            );
                          }}
                          disabled={
                            !isDatePassedTenDays(client.AWOL_DATE_MAILED) ||
                            !isAdmin(currentUser)
                          }
                          style={{
                            width: '125px',
                            padding: '4px',
                            fontSize: '0.675em',
                            borderRadius: '4px',
                          }}
                        />
                      </span>
                    </td>{' '}
                    {/* Admin Name Column  */}
                    <td style={{ fontSize: '1.2em' }}>{client.AdminMailed}</td>
                    {/* Still Awol Column */}
                    <td className={styles.centeredRadio}>
                      <Tooltip
                        title={
                          !client.AWOL_DATE_MAILED ||
                          !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                            ? 'This button is disabled until the date has been entered by admin for Letter Mailed On... and 10 days have passed since that date.'
                            : ''
                        }
                      >
                        <span>
                          <input
                            type="radio"
                            checked={
                              radioSelections[client.ta_mrn] ===
                                'remained_awol' &&
                              client.AWOL_DATE_MAILED &&
                              isDatePassedTenDays(client.AWOL_DATE_MAILED)
                            }
                            disabled={
                              !client.AWOL_DATE_MAILED ||
                              !isDatePassedTenDays(client.AWOL_DATE_MAILED)
                            }
                            onChange={() =>
                              handleRadioChange(
                                client.ta_mrn,
                                'remained_awol',
                                client.provider_id
                              )
                            }
                          />
                        </span>
                      </Tooltip>
                    </td>
                    {/* Client Returned */}
                    <td className={styles.centeredRadio}>
                      <input
                        type="radio"
                        checked={radioSelections[client.ta_mrn] === 'returned'}
                        onChange={() =>
                          handleRadioChange(
                            client.ta_mrn,
                            'returned',
                            client.provider_id
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      ) : !isAdmin(currentUser) || selectedProvider.id ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: isMobile ? '15vh' : '15vh', // Ensures the message is centered in the viewport
            width: '100%', // Ensures full width
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row', // Use row to keep icon and text side by side
              alignItems: 'center', // Align items centrally in the row
              textAlign: 'center',
              fontSize: '1.5rem', // Larger text size
            }}
          >
            <CheckCircleIcon
              style={{
                color: 'green',
                marginRight: isMobile ? '-8px' : '10px', // More space on mobile
                fontSize: isMobile ? '2.5rem' : '3rem', // Adjust icon size based on device
                alignSelf: isMobile ? 'flex-start' : 'center', // Align icon higher on mobile
                marginTop: isMobile ? '5px' : '0', // Move icon up on mobile
              }}
            />
            <span>
              {isAdmin(currentUser)
                ? 'This user does not currently have any clients on this list.'
                : 'You currently do not have any clients on this list.'}
            </span>
          </div>
        </div>
      ) : null}

      {confirmationModalOpen && (
        <div className={styles.confirmationModalBackdrop}>
          <div className={styles.confirmationModal}>
            <p>
              In order to make this client "{selectedStatus}" please confirm
              that you have done the following:
            </p>
            <ul>
              <li>Written a closing summary in TA as a quick note</li>
              <li>Closed the status in TA to {selectedStatus}</li>
            </ul>
            <button onClick={handleConfirmSubmit}>
              I ACKNOWLEDGE I COMPLETED THESE STEPS
            </button>
            <button onClick={handleCancelConfirmation}>Cancel</button>
          </div>
        </div>
      )}

      <ConfirmationModal
        isVisible={specificConfirmationModalOpen}
        onConfirm={handleConfirmSubmit}
        onCancel={handleCancelConfirmation}
        modalMessageType={modalMessageType}
      />

      {/* <AllConfirmedModal
        isVisible={isAllConfirmedModalVisible}
        onSubmit={handleCombinedSubmit}
      /> */}

      {successMessage && (
        // I don't think a class name of success-message exists
        // <div className="success-message">{successMessage}</div>
        <div>{successMessage}</div>
      )}
    </div>
  );

  function ConfirmationModal({
    isVisible,
    onConfirm,
    onCancel,
    modalMessageType,
  }) {
    if (!isVisible) return null;

    // This function determines the message to display based on the modalMessageType
    const getMessage = () => {
      switch (modalMessageType) {
        case 'never_showed':
          return <p>Confirm That You Have Made This Client InActive in TA?</p>;
        case 'agreed_termination':
          return (
            <div>
              <p>Confirm That</p>
              <ul>
                <li>You Have Written A Termination Summary in TA Notes?</li>
                <li>You Have Made This Client InActive in TA?</li>
              </ul>
            </div>
          );

        case 'remained_awol':
          const handleCopyToClipboard = () => {
            navigator.clipboard
              .writeText(sampleNote)
              .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 2000);
              })
              .catch((error) => {
                console.error('Failed to copy text: ', error);
              });
          };

          return (
            <div>
              <p
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '1.2em',
                }}
              >
                Confirm all of the following:
              </p>
              <div style={{ height: '1em' }}></div>
              <ul>
                <li>1. Your client remained AWOL</li>
                {/* <li className="list-item">1. Your client remained AWOL</li> */}
                <li>2. You have made the client Inactive in TA</li>
                <li>
                  3. You have written a "quick note" in TA about the client
                  remaining AWOL.
                  <span
                    className={styles.hoverText}
                    title={sampleNote} // Added title attribute for hover effect
                  >
                    Hover over here
                  </span>{' '}
                  to view a sample quick note that you can use.
                </li>
              </ul>
              <Button
                className={styles.copyButton}
                variant="outlined"
                color="primary"
                onClick={handleCopyToClipboard}
                startIcon={<FileCopyIcon style={{ marginRight: 8 }} />} // Add margin to the icon
                sx={{
                  marginTop: '16px',
                  display: 'block',
                  margin: 'auto',
                  padding: '10px 20px', // Adjust padding for better spacing
                }}
              >
                {isCopied ? 'Copied!' : 'Copy Sample Quick Note'}
              </Button>
            </div>
          );

        default:
          return <p>Are you sure you want to confirm this selection?</p>;
      }
    };

    return (
      <div className={styles.confirmationModalBackdrop}>
        <div className={styles.modalContent}>
          {getMessage()}

          <div>
            <ButtonGroup>
              <Button
                variant="contained"
                color="error" // Use MUI's predefined color options
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button variant="contained" color="success" onClick={onConfirm}>
                Confirm
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    );
  }
  // function AllConfirmedModal({ isVisible, onSubmit }) {
  //   if (!isVisible) return null;

  //   return (
  //     <div className={styles.confirmationModalBackdrop}>
  //       <div className={styles.confirmationModal}>
  //         <p>
  //           All clients have been accounted for. Click the "Submit" button below
  //           to finalize and confirm your Active Rosters for this month.
  //         </p>

  //         <div className={styles.modalButtonContainer}>
  //           <button onClick={onSubmit} className={styles.submitButton}>
  //             Submit
  //           </button>
  //           <button
  //             onClick={() => setIsAllConfirmedModalVisible(false)}
  //             className={styles.cancelButton}
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}
