import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import WaitListConfirmationDialog from './Dialogs/WaitListJotFormDialog';

export default function EntryTypeForm(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [urlToOpen, setUrlToOpen] = useState('');

  /**
   * The below are the two conditions for mediaQuery themes
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDialog = (url) => {
    setDialogOpen(true);
    setUrlToOpen(url);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDialog = () => {
    window.open(urlToOpen, '_blank');
    setDialogOpen(false);
  };

  const handleChange = (event) => {
    try {
      const {
        target: { value, name },
      } = event;

      if (name === 'cancellationOptions' && value === 'existing') {
        window.open('https://hipaa.jotform.com/220945182806155', '_blank');
      }
      if (name === 'cancellationOptions' && value === 'new') {
        window.location.href = '/reminders';
      }

      // Special logic for 'waitlist'
      if (name === 'entryType' && value === 'waitlist') {
        handleOpenDialog('https://form.jotform.com/241716073337152'); // Put the actual JotForm URL here
      }

      const entryTypeFormValuesClone = { ...props.entryTypeFormValues };
      entryTypeFormValuesClone[name] = value;
      props.setEntryTypeFormValues(entryTypeFormValuesClone);
    } catch (error) {
      console.error('EntryTypeForm.handleChange.error', error);
    }
  };
  return (
    <React.Fragment>
      <div className="main-container">
        <div>
          <FormControl
            sx={{
              marginTop: isSmallScreen ? '-30px' : '0px',
            }}
          >
            <FormLabel id="demo-row-radio-buttons-group-label" required={true}>
              Entry Type{' '}
            </FormLabel>
            <RadioGroup
              row={!isSmallScreen}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="entryType"
              defaultValue="new"
              onChange={handleChange}
            >
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="Appt - Brand New Client"
              />
              <FormControlLabel
                value="existing"
                control={<Radio />}
                label="Appt - Existing Client"
              />
              <FormControlLabel
                value="returning"
                control={<Radio />}
                label="Appt - Returning Client"
              />
              <FormControlLabel
                value="cancellation"
                control={<Radio />}
                label="Cancellation"
              />
              <FormControlLabel
                value="waitlist"
                control={<Radio />}
                label="Waitlist"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {props.entryTypeFormValues.entryType === 'existing' && (
          <div>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                required={true}
              >
                Existing Client Options{' '}
              </FormLabel>
              <RadioGroup
                row={!isSmallScreen}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="existingClientOptions"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Transfer"
                  control={<Radio />}
                  label="Transfer"
                />
                <FormControlLabel
                  value="Testing Client Coming to Office"
                  control={<Radio />}
                  label="Testing Client Coming to Office"
                />
                <FormControlLabel
                  value="Therapy Client Coming to Office"
                  control={<Radio />}
                  label="Therapy Client Coming to Office"
                />
                <FormControlLabel
                  value="Turning 18 years-Old"
                  control={<Radio />}
                  label="Turning 18 years-Old"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {props.entryTypeFormValues.entryType === 'returning' && (
          <div>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                required={true}
              >
                Has 6 Months Passed Since Client Was Last Seen?{' '}
              </FormLabel>
              <RadioGroup
                row={!isSmallScreen}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="returningClientQ1"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes (New Informed Consent Needed)"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No (No New Informed Consent Needed)"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {props.entryTypeFormValues.entryType === 'cancellation' && (
          <div>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                required={true}
              >
                Cancellation
              </FormLabel>
              <RadioGroup
                row={!isSmallScreen}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="cancellationOptions"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="new"
                  control={<Radio />}
                  label="1st Appt"
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio />}
                  label="After 1st Appt"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        <WaitListConfirmationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDialog}
          title="Wait List for Externs Message"
          message={
            <React.Fragment>
              <p style={{ color: 'red' }}>
                Please ensure the following details are completed before
                proceeding:
              </p>
              <div style={{ marginBottom: '10px' }}></div>{' '}
              {/* Line space before the list */}
              <ul>
                <li style={{ fontWeight: 'bold' }}>Date of Birth</li>
                <li style={{ fontWeight: 'bold' }}>Insurance</li>
                <li style={{ fontWeight: 'bold' }}>Service</li>
                <li style={{ fontWeight: 'bold' }}>Type of Service</li>
                <li style={{ fontWeight: 'bold' }}>Risk Questions</li>
              </ul>
              <p style={{ color: 'blue' }}>
                Once the above fields are completed, you would select Manual
                Entry and at least 1 Extern should show in results.
              </p>
              <div style={{ marginBottom: '10px' }}></div>
              <p style={{ color: 'blue' }}>
                When you click continue the Waitlist Jotform will open in a new
                tab.{' '}
              </p>
            </React.Fragment>
          }
        />
      </div>
    </React.Fragment>
  );
}
