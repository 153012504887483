import React, { useEffect, memo } from 'react';
import {
  Autocomplete,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import EventBus from '../../common/EventBus';
import {
  getBirthdayEventsAndDelete,
  createOrUpdateBirthdayEvent,
  createOrUpdateLicenseEvent,
  getLicenseEventsAndDelete,
  createOrUpdateAnniversaryEvent,
  getAnniversaryEventsAndDelete,
} from '../../common/utils/DateUtils';
import styles from '../../styles/ProviderConfig.module.css';
import { adminService, driveService, providerService } from '../../services';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ProviderSystemInfo = (props) => {
  const [inTesting, setInTesting] = React.useState('');
  const [inTherapy, setInTherapy] = React.useState('');
  const [providerStatus, setProviderStatus] = React.useState(false);
  // The below state is use to track if providerStatus was changed.
  const [providerStatusChanged, setProviderStatusChanged] =
    React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date('2025-12-31'));
  const [endDate, setEndDate] = React.useState(new Date('2025-12-31'));

  const [adminHandlingName, setAdminHandlingName] = React.useState('');
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [staticDoxyLink, setStaticDoxyLink] = React.useState('');
  const [pw, setPw] = React.useState();
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const isDoctorAdminSupervisor = (user) => {
    return (
      user &&
      user.roles &&
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInTesting(props.selectedUserDetail?.in_testing ?? '');
    setProviderStatus(Boolean(props.selectedUserDetail?.is_active) ?? '');
    setInTherapy(props.selectedUserDetail?.in_therapy ?? '');
    setStaticDoxyLink(props.selectedUserDetail?.static_doxy_link);
    setPw(props.selectedUserDetail?.pw);
    setAdminHandlingName(props.selectedUserDetail?.admin_handling_name);
    setStartDate(props.selectedUserDetail?.date_start);
    setEndDate(props.selectedUserDetail?.date_end);
  }, [props.selectedUserDetail]);

  useEffect(() => {
    adminService.getAdmins().then(
      async (response) => {
        /*
         The below has been updated to fetch admins from the Admin table
         */
        let adminUsers = response?.data ?? [];
        adminUsers = adminUsers.sort((adminUser1, adminUser2) => {
          const name1 = adminUser1?.admin_name?.split(' ')[1] ?? '';
          const name2 = adminUser2?.admin_name?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });
        setAdminUsers(adminUsers);
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  const handleOnSubmit = async (event) => {
    try {
      event.preventDefault();

      if (props?.selectedUser?.id) {
        if (props.selectedUserDetail?.provider_email) {
          props.showLoading(); // showing the loading
          const response = await providerService.updateProviderDetails(
            props.selectedUser.id,
            {
              email: props.selectedUserDetail?.provider_email,
              pw: pw,
              inTesting,
              inTherapy,
              adminHandlingName,
              staticDoxyLink,
              is_active: providerStatus,
              date_start: startDate,
              date_end: endDate,
              supervisorEmail: props.selectedUserDetail?.supervisor_email,
              supervisorName: props.selectedUserDetail?.supervisor_name,
              degreesAndLicense: props.selectedUserDetail?.degrees_and_license,
              licensedInOtherStates:
                props.selectedUserDetail?.licensed_in_other_states,
              aetna_effective_date:
                props.selectedUserDetail?.aetna_effective_date,
              bcbs_effective_date:
                props.selectedUserDetail?.bcbs_effective_date,
              cigna_effective_date:
                props.selectedUserDetail?.cigna_effective_date,
              hopkins_ehp_effective_date:
                props.selectedUserDetail?.hopkins_ehp_effective_date,
              hopkins_usfhp_effective_date:
                props.selectedUserDetail?.hopkins_usfhp_effective_date,
              medicaid_effective_date:
                props.selectedUserDetail?.medicaid_effective_date,
              tricare_effective_date:
                props.selectedUserDetail?.tricare_effective_date,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Provider details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          }

          // The below is the case when provider is being made inactive
          // we would need to delete the event on calendar
          if (providerStatus === false) {
            // Now here I am moving the drive Folder to expired (userId) (First step required)
            await driveService.moveProviderFolder(
              props?.selectedUserDetail?.id
            );

            await getBirthdayEventsAndDelete(
              props?.selectedUserDetail?.provider_name
            );

            // As I am not passing state so i am deleting liability
            await getLicenseEventsAndDelete(
              props?.selectedUserDetail?.provider_name
            );

            // Below I am removing license information from calendar
            const states =
              props?.selectedUserDetail?.licensed_in_other_states?.split(',');

            states.map(async (state) => {
              await getLicenseEventsAndDelete(
                props?.selectedUserDetail?.provider_name,
                state
              );
            });

            // Now here I am deleting license in maryland state
            await getLicenseEventsAndDelete(
              props?.selectedUserDetail?.provider_name,
              'Maryland'
            );

            // Now here I am deleting license in PsyPact
            await getLicenseEventsAndDelete(
              props?.selectedUserDetail?.provider_name,
              'PsyPact'
            );

            // The below I am creating the Anniversary Date
            await getAnniversaryEventsAndDelete(
              props?.selectedUserDetail?.provider_name
            );
          } else {
            // The below condition makes sure that if providerStatus was changed
            if (providerStatusChanged) {
              // Passing username and date of birth for the creation of birthday event
              await createOrUpdateBirthdayEvent(
                props?.selectedUserDetail?.provider_name,
                moment
                  .utc(props?.selectedUserDetail?.date_of_birth)
                  .format('YYYY-MM-DD')
              );
              // if there is expiration date for maryLand then create it otherwise leave it
              if (
                props?.selectedUserDetail?.marylandLicenseDetails
                  ?.expirationDate
              ) {
                // creating maryland license
                await createOrUpdateLicenseEvent(
                  props?.selectedUserDetail?.provider_name,
                  props?.selectedUserDetail?.marylandLicenseDetails
                    ?.expirationDate,
                  'Maryland'
                );
              }

              // The below function for creating/updating liability
              await createOrUpdateLicenseEvent(
                props?.selectedUserDetail?.provider_name,
                props?.liabilityFileExpirationDate
              );

              // creating license in other states
              for (const state in props?.selectedUserDetail
                ?.licenseInOtherStateDetails) {
                if (
                  props?.selectedUserDetail?.licenseInOtherStateDetails.hasOwnProperty(
                    state
                  )
                ) {
                  const expirationDate =
                    props?.selectedUserDetail?.licenseInOtherStateDetails[state]
                      .expirationDate;
                  await createOrUpdateLicenseEvent(
                    props?.selectedUserDetail?.provider_name,
                    expirationDate,
                    state
                  );
                }
              }
            }

            // // The below I am creating the Anniversary Date
            await createOrUpdateAnniversaryEvent(
              props?.selectedUserDetail?.provider_name,
              startDate
            );
          }

          props.hideLoading(); // hiding the loading

          enqueueSnackbar('Updated Provider ', {
            variant: 'success',
            timeout: 3000,
          });
          //forcefully reloading the window because status has been set to false.
          if (providerStatus === false) {
            window.location.reload();
          }

          props.updateProviderDetail();
        }
      } else {
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    }
  };

  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className={styles.providerNotice1}>
          All sections of the provider configuration is read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className={styles.providerNotice1}>
            The "Personal Info" section of the provider profile is read-only.
            Please reach out to Levi or Victoria if there is any information
            that you would like to update.
          </Typography>
        )}

      <div class={styles.tabsContentContainer}>
        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          {shouldShow && (
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  Provider Active Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={providerStatus}
                  onChange={(event) => {
                    setProviderStatus(event.target.value);
                    setProviderStatusChanged(true);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          )}

          <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <TextField
              fullWidth
              autoComplete="off"
              variant="outlined"
              id="start_date"
              label="Start Date"
              value={startDate}
              type="date"
              disabled={props.isProvider}
              componentsProps={{
                label: {
                  shrink: true,
                },
              }}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </Grid2>

          {providerStatus === false && (
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="end_date"
                label="End Date"
                value={endDate}
                type="date"
                disabled={props.isProvider}
                componentsProps={{
                  label: {
                    shrink: true,
                  },
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Grid2>
          )}

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <Autocomplete
                disablePortal
                id="admin_handling_name"
                label="Admin Handling"
                defaultValue={adminHandlingName}
                options={adminUsers}
                value={adminHandlingName}
                disabled={props.isProvider}
                onChange={(event, value) => {
                  setAdminHandlingName(value?.admin_name);
                }}
                renderInput={(params) => (
                  <TextField
                    className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                    variant="outlined"
                    {...params}
                    name="adminHandling_"
                    label="Admin Handling"
                  />
                )}
                getOptionLabel={
                  (option) => option.admin_name || adminHandlingName
                  // The above getting adminName
                }
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="static_doxy_link"
                label="Static Doxy Link"
                value={staticDoxyLink}
                disabled={props.isProvider}
                onChange={(e) => {
                  setStaticDoxyLink(e.target.value);
                }}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <TextField
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                autoComplete="off"
                variant="outlined"
                id="pw"
                label="Password"
                value={pw}
                disabled={props.isProvider}
                onChange={(e) => {
                  setPw(e.target.value);
                }}
              />
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  In Testing
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inTesting}
                  label="In Testing"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setInTesting(event.target.value);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          }

          {
            <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
              <FormControl
                className={`${styles.fullWidthTextField} ${styles.borderedDropdown}`}
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  In Therapy
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inTherapy}
                  label="In Therapy"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setInTherapy(event.target.value);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
          }
        </Grid2>

        <Grid2 container spacing={3} className={styles.gridItemTextField}>
          <Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
            <Button
              variant="contained"
              color="primary"
              className={styles.buttonWithMargin}
              onClick={handleOnSubmit}
              disabled={props.isProvider}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderSystemInfo);
