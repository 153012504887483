export const filterDocuments = (docs, filters, userSessionDetails) => {
  // First, filter all documents according to the given criteria
  const filteredDocs = docs.filter((doc) => {
    const matches = {
      serviceGroup:
        doc.service_group === 'All' ||
        !filters.serviceGroup ||
        doc.service_group.toLowerCase().trim() ===
          filters.serviceGroup.toLowerCase().trim(),
      serviceType:
        doc.service_sub_type === 'All' ||
        !filters.serviceType ||
        doc.service_sub_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.serviceType.toLowerCase().trim()),
      clientType:
        doc.client_type === 'All' ||
        !filters.clientType ||
        doc.client_type
          .toLowerCase()
          .trim()
          .split(', ')
          .includes(filters.clientType.toLowerCase().trim()),
      ageRange:
        doc.age_range === 'All' ||
        !filters.age ||
        (doc.age_range === '18+' ? filters.age >= 18 : filters.age < 18),
      licenseStatus:
        doc.license_status === 'All' ||
        !filters.licenseStatus ||
        doc.license_status.toLowerCase().trim() ===
          filters.licenseStatus.toLowerCase().trim(),
      specialScenario:
        doc.special_scenario === null ||
        isSpecialScenarioMet(doc, userSessionDetails),
    };

    return Object.values(matches).every(Boolean);
  });

  // Modify here: Exclude the influence of `isTurning18` from excluding other documents
  if (userSessionDetails.isTurning18) {
    return filteredDocs; // If `isTurning18` is true, do not exclude any documents that meet the criteria
  } else {
    // Handle other special scenarios that might require exclusions
    const specialScenarioDocs = filteredDocs.filter(
      (doc) => doc.special_scenario
    );
    const regularDocs = filteredDocs.filter((doc) => !doc.special_scenario);

    return [...new Set([...specialScenarioDocs, ...regularDocs])]; // Combine and remove duplicates
  }
};

// The below function sorts the documents based on their doc_name
export const sortDocuments = (documents) => {
  return documents.sort((a, b) => {
    const aPrefixMatch = a.doc_name.match(/^(\d+)\./);
    const bPrefixMatch = b.doc_name.match(/^(\d+)\./);

    if (aPrefixMatch && bPrefixMatch) {
      return parseInt(aPrefixMatch[1]) - parseInt(bPrefixMatch[1]);
    }
    if (aPrefixMatch) {
      return -1;
    }
    if (bPrefixMatch) {
      return 1;
    }
    return a.doc_name.localeCompare(b.doc_name);
  });
};

export const isSpecialScenarioMet = (document, userSessionDetails) => {
  if (document.special_scenario && document.special_scenario.trim() !== '') {
    switch (document.special_scenario.trim()) {
      case 'Pro Bono':
        return userSessionDetails.isProBono;

      case 'Adoptions':
        return userSessionDetails.isAdoptions; // Assuming you have a flag for simple 'Adoptions'

      case 'Adult ADHD':
        return userSessionDetails.isAdultADHD;

      case 'Adult Autism':
        return userSessionDetails.isAdultAutism;

      case 'Bariatrics':
        return userSessionDetails.isBariatric;

      case 'Bariatrics - Hopkins':
        return userSessionDetails.isBariatricHopkins;

      case 'Bariatrics - Other':
        return userSessionDetails.isBariatricNOTHopkins;

      case 'Couples - Insurance':
        return userSessionDetails.isCouplesInsurance; // Assuming you add this flag

      case 'Couples - PP':
        return userSessionDetails.isCouplesPP; // Assuming you add this flag

      case 'Families - Insurance':
        return userSessionDetails.isFamiliesInsurance; // Assuming you add this flag

      case 'Families - PP':
        return userSessionDetails.isFamiliesPP; // Assuming you add this flag

      case 'Turning 18':
        return userSessionDetails.isTurning18;

      default:
        return false; // Return false for unhandled scenarios
    }
  }
  return true; // If special_scenario is empty or not set, return true to ignore special scenario filtering
};
