import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import { useSnackbar } from 'notistack';
import { userService, providerService } from '../../services';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';

function compareProviderFullName(a, b) {
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

export default function ProviderProfessionalInfoTable({ showActiveProviders }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';
    showLoading();

    providerService
      .getProviders(status)
      .then((response) => {
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        return userService.getAllUsers().then((usersResponse) => {
          const users = usersResponse?.data?.users ?? [];

          const providersWithImages = fetchedProviders.map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          // Fetch additional details for each provider
          const fetchDetailsPromises = providersWithImages.map((provider) =>
            getProviderDetail(provider.id, provider)
              .then((detail) => ({ ...provider, ...detail }))
              .catch((error) => {
                console.error(
                  'Failed to fetch details for provider:',
                  provider.id,
                  error
                );
                return provider;
              })
          );

          Promise.all(fetchDetailsPromises).then((completedProviders) => {
            const validProviders = completedProviders.filter(
              (provider) => provider.provider_name != null
            );
            setLocations(validProviders);
            setProviders(validProviders);
          });
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showActiveProviders, enqueueSnackbar, showLoading, hideLoading]);

  const getProviderDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedProviders = useMemo(() => {
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareProviderFullName);
  }, [locations, page, rowsPerPage]);

  const formattedProviders = useMemo(() => {
    return displayedProviders.map((provider) => {
      // Format the diplomas field
      let diplomasContent = 'N/A';
      if (provider.diplomas && provider.diplomas.length > 0) {
        diplomasContent = provider.diplomas
          .map((diploma) => {
            const graduatingDate = new Date(
              diploma.graduating_date
            ).toLocaleDateString('en-US', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
            });
            return `${diploma.graduating_school}\n(${graduatingDate})`;
          })
          .join('\n\n');
      }

      return {
        ...provider,
        diplomas: diplomasContent,
      };
    });
  }, [displayedProviders]);

  const columns = useMemo(
    () => [
      {
        field: 'provider_name',
        headerName: 'Full Name',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'supervisor_name',
        headerName: 'Supervisor',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
      {
        field: 'diplomas',
        headerName: 'Graduating School\n(Graduating Date)',
        headerStyle: {
          borderBottom: '0.5px solid black',
          fontWeight: '900',
          position: 'sticky',
          top: '0px',
          zIndex: 100,
          backgroundColor: '#F5F5F5',
        },
        cellStyle: {
          borderBottom: '0.1px solid black',
        },
      },
    ],
    []
  );
  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    fontSize: '1.0rem', // Increase font size
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={formattedProviders}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
