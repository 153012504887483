export const therapyAlertMapping = [
  { insurances: 'Private Pay', alert: '' },
  { insurances: 'Aetna', alert: '' },
  {
    insurances: 'Aetna EAP',
    alert:
      'Remind client to use group name and group Tax ID when obtaining their auth',
  },
  { insurances: 'Aetna Medicare Advantage', alert: '' },
  { insurances: 'BCBS', alert: '' },
  { insurances: 'CIGNA', alert: '' },
  { insurances: 'CIGNA EAP', alert: '' },
  { insurances: 'Johns Hopkins EHP', alert: '' },
  { insurances: 'Johns Hopkins USFHP', alert: '' },
  { insurances: 'Medical Assistance', alert: '' },
  {
    insurances: 'Medicare',
    alert:
      'Make sure office location selection in TA scheduling  matches: Novitas must have Bal/Col selected and Medicare DC must have SS selected as office location',
  },
  { insurances: 'Tricare Standard/Indeminity (NOT Prime)', alert: '' },
];

export const testingAlertMapping = [
  { insurances: 'Private Pay', alert: '' },
  {
    insurances: 'Aetna',
    alert:
      'Self Funded - Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person. Commercial - Virtual Testing Covered - Can Schedule 1- 3 Hour Virtual Session',
  },
  {
    insurances: 'Aetna EAP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Aetna Medicare Advantage',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },

  {
    insurances: 'BCBS',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'CIGNA',
    alert:
      'U Plans - Virtual Testing Covered - Can Schedule 1- 3 Hour Virtual Session Non U Plans - Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person',
  },
  { insurances: 'CIGNA EAP', alert: '' },
  {
    insurances: 'Johns Hopkins EHP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Johns Hopkins USFHP',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Medical Assistance',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  {
    insurances: 'Medicare',
    alert:
      'Virtual Testing Not Covered - 1st Ses-Virtual / 2nd Session In Person.',
  },
  { insurances: 'Tricare Standard/Indeminity (NOT Prime)', alert: '' },
];

export const riskTherapyAlertMapping = [
  {
    question: 'Suicidal Thoughts',
    alert:
      '1. Please note this in TA in the corresponding Question. 2. A YES answer for the suicide question precludes the client from seeing a clinician for remote sessions. The filters below have been adjust to exclude remote session and for providers that work clients in person that have a recent history of ACTIVE suicidal thoughts',
  },
  {
    question: 'Self-harm',
    alert:
      '1. Please note this in TA in the corresponding Question. 2. A YES answer for the self-harm question does NOT automatically preclude the client from being seen remotely as some providers will see clients with a history of self-harm.  The filters below have been adjusted to only show such providers',
  },
  {
    question: 'Psychiatric Hospitalization',
    alert:
      '1. Please note this in TA in the corresponding Question. A YES answer for the recent psychiatric hospitalization question precludes the client from seeing a clinician for remote sessions. The filters below have been adjust to exclude remote session and for provides that work in-person for such clients',
  },
];

export const riskTestingAlertMapping = [
  {
    question: 'Suicidal Thoughts',
    alert: 'Please note this in TA in the corresponding Question',
  },
  {
    question: 'Self-harm',
    alert: 'Please note this in TA in the corresponding Question',
  },
  {
    question: 'Psychiatric Hospitalization',
    alert: 'Please note this in TA in the corresponding Question',
  },
];
