import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  Tooltip,
  TablePagination,
  Autocomplete,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  RemoveRedEye,
  Create,
} from '@mui/icons-material';
import EventBus from '../common/EventBus';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { durations } from '../common/utils/ScheduleUtils.js';
import { isUserAdminOrCareCoordinator } from '../common/utils/RoleUtils.js';
import styles from '../styles/Reminders.module.css';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../actions/loading';
import {
  appointmentService,
  complianceService,
  userService,
} from '../services';
import { CancelSurveyDialog } from './Dialogs';
import ReminderCard from './UI/ReminderCard.js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

export default function Reminders() {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [isRescheduleActive] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  // The below two are used for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedDuration, setSelectedDuration] = React.useState(['60']);
  const handleChangeDuration = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDuration(typeof value === 'string' ? value.split(',') : value);
  };

  const [careCoordinators, setCareCoordinators] = useState([]);
  const [adminOrCareCoordinators, setAdminOrCareCoordinators] = useState([]);
  const [showRedirectionUpdateModal, setShowRedirectionUpdateModal] =
    React.useState(false);
  const [showRedirectionCancelModal, setShowRedirectionCancelModal] =
    React.useState(false);
  const [selectedCareCoordinator, setSelectedCareCoordinator] = React.useState(
    []
  );
  const [reason, setReason] = React.useState('');
  const [isCancel, setIsCancel] = React.useState(false);
  const [sortOrder, setSortOrder] = React.useState(true);
  const [selectedSlotId, setSelectedSlotId] = React.useState();
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedReminder, setSelectedReminder] = React.useState();
  const [selectedAppointmentId, setSelectedAppointmentId] = React.useState();
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const [reminders, setReminders] = React.useState([]);
  const [radioGroupValue, setRadioGroupValue] = React.useState([]);
  const [remindersMasterList, setRemindersMasterList] = React.useState([]);
  const [reminderPreview, setReminderPreview] = React.useState();
  const [selectedMainFilter, setSelectedMainFilter] = React.useState('1');
  const [selectedSubFilter, setSelectedSubFilter] = React.useState('3');
  const [cancelOpenSlot, setCancelOpenSlot] = React.useState(false);
  const [selectedProviderAvailabilityId, setSelectedProviderAvailabilityId] =
    useState(null);
  const [openEmailReminder, setOpenEmailReminder] = useState(false);
  const [openCancelSurvey, setOpenCancelSurvey] = useState(false);

  const handleMainFilterChange = (event) => {
    setSelectedMainFilter(event.target.value);
    if (event.target.value === '1') {
      setSelectedSubFilter('3');
    } else if (event.target.value === '2') {
      setSelectedSubFilter('7');
    }
  };

  const handleOpenEmailReminder = () => {
    setOpenEmailReminder(true);
  };

  const handleCloseEmailReminder = () => {
    setOpenEmailReminder(false);
  };

  const handleOpenCancelSurvey = (providerAvailabilityId) => {
    // Set the selected provider availability ID
    setSelectedProviderAvailabilityId(providerAvailabilityId);

    // Then open the dialog
    setOpenCancelSurvey(true);
  };

  const handleCloseCancelSurvey = () => {
    setOpenCancelSurvey(false);
  };

  const [openSettingModal, setOpenSettingModal] = React.useState(false);

  const handleCombinedClick = (providerAvailabilityId) => {
    handleSelectProviderAvailability(providerAvailabilityId);
    handleCancelSurvey();
  };

  const handleSelectProviderAvailability = (id) => {
    setSelectedProviderAvailabilityId(id);
  };

  const handleCancelSurvey = () => {
    if (selectedProviderAvailabilityId) {
      showLoading(); // Show loading indicator
      complianceService
        .cancelSurvey(selectedProviderAvailabilityId) // Assuming this is already a number
        .then((response) => {
          // Handle success
          hideLoading(); // Hide loading indicator

          console.log(response.data.message);
          handleCloseCancelSurvey(); // Assuming this is your function to close the modal
          // Further UI updates or state changes
          // Find the item in reminders array and update it
          const updatedReminders = reminders.map((reminder) => {
            if (
              reminder.provider_availability_id ===
              selectedProviderAvailabilityId
            ) {
              return { ...reminder, survey_cancelled: 1 }; // Update the survey_cancelled property
            }
            return reminder;
          });

          // Set the updated reminders array to state
          setReminders(updatedReminders);

          console.log(response.data.message);
        })
        .catch((error) => {
          // Handle errors
          hideLoading(); // Hide loading indicator
          console.error(
            'Error cancelling survey:',
            error.response?.data?.message || error.message
          );
        });
    } else {
      console.error('No provider availability selected');
    }
  };

  const handleCloseSettingModal = () => setOpenSettingModal(false);
  const handleOpenSettingModal = () => setOpenSettingModal(true);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const sortReminders = (remindersArray) => {
    const currentDate = moment();
    return remindersArray.sort((a, b) => {
      const aDateTime = moment.utc(
        `${a.appointment_date} ${a.appointment_start_time}`,
        'YYYY-MM-DD HH:mm:ss'
      );
      const bDateTime = moment.utc(
        `${b.appointment_date} ${b.appointment_start_time}`,
        'YYYY-MM-DD HH:mm:ss'
      );
      const aDiff = Math.abs(currentDate.diff(aDateTime));
      const bDiff = Math.abs(currentDate.diff(bDateTime));
      return aDiff - bDiff;
    });
  };

  useEffect(() => {
    const time = moment(selectedStartDate, 'YYYY-MM-DDTHH:mm')
      .add(selectedDuration, 'm')
      .toDate();
    setSelectedEndDate(moment(time).format('YYYY-MM-DDTHH:mm'));
  }, [selectedDuration, selectedStartDate]);

  useEffect(() => {
    userService.getAllUsers().then(
      (response) => {
        let allUsers = response?.data?.users;
        allUsers = allUsers.filter((allUsersObj) => allUsersObj.is_active);

        const adminsAndCareCoordinators = allUsers.filter((user) =>
          [3, 6].includes(user.roleId)
        );
        const onlyCareCoordinators = allUsers.filter(
          (user) => user.roleId === 6
        );

        setAdminOrCareCoordinators(adminsAndCareCoordinators);
        setCareCoordinators(onlyCareCoordinators);
      },
      (error) => {
        console.log(
          'Error fetching users:',
          error.response?.data?.message || error.message || error.toString()
        );
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  useEffect(() => {
    userService.getAllUsers().then(
      (response) => {
        let usersArr = response?.data?.users.filter(
          isUserAdminOrCareCoordinator
        );
        const allUsers = response?.data?.users;
        setAdminOrCareCoordinators(usersArr);
        appointmentService.getAppointments().then(
          (response) => {
            let reminderArr = response?.data?.reminders ?? [];
            reminderArr = reminderArr.map((obj) => {
              let userObjFound = allUsers.find((userObj) => {
                return userObj.id === obj.provider_id;
              });
              obj.provider = userObjFound;
              return obj;
            });

            // Sort the reminders before setting the state
            const sortedReminders = sortReminders(reminderArr);
            setReminders(sortedReminders);
            setRemindersMasterList(sortedReminders);
            setRadioGroupValue(1);
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            console.log('_content: ', _content);

            if (error.response && error.response.status === 401) {
              EventBus.dispatch('logout');
            }
          }
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content ', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const onTagsChange = (event, values) => {
    setSelectedCareCoordinator(values?.id);
  };

  useEffect(() => {
    let updatedReminders = { ...remindersMasterList };

    const cur = moment();

    if (selectedMainFilter === '1') {
      // Filter appointments that are in the future from the current day and time.
      updatedReminders = remindersMasterList.filter((reminder) => {
        const apptDate = moment(
          reminder.appointment_start_time,
          'YYYY-MM-DDTHH:mm:ss.SSSSZ'
        );
        return apptDate.isAfter(cur);
      });
      if (selectedSubFilter === '3' && selectedMainFilter === '1') {
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder?.email_status === 'pending';
        });
      } else if (selectedSubFilter === '4' && selectedMainFilter === '1') {
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder?.email_status === 'Sent';
        });
      } else if (selectedSubFilter === '5' && selectedMainFilter === '1') {
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder?.email_status === 'cancelled';
        });
      }
    } else if (selectedMainFilter === '2') {
      // Show past appointments
      updatedReminders = remindersMasterList.filter((reminder) => {
        const apptDate = moment(
          reminder.appointment_start_time,
          'YYYY-MM-DDTHH:mm:ss.SSSSZ'
        );
        return apptDate.isBefore(cur);
      });
      if (selectedSubFilter === '7' && selectedMainFilter === '2') {
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder.email_status === 'Sent';
        });
      } else if (selectedSubFilter === '8' && selectedMainFilter === '2') {
        updatedReminders = updatedReminders.filter((reminder) => {
          return reminder.status === 'cancelled';
        });
      } // No need for an else if for '9' (All) as it doesn't require any additional filtering.
    }

    updatedReminders = sortReminders(updatedReminders);

    setReminders(updatedReminders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    radioGroupValue,
    remindersMasterList,
    selectedMainFilter,
    selectedSubFilter,
  ]);

  // The below useEffect is for disabling the button and button will be enabled only when user have filled in the form
  useEffect(() => {
    if (selectedCareCoordinator && reason) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedCareCoordinator, reason]);

  const handleSearch = (event) => {
    try {
      const keyword = event.target.value;
      let updatedReminders = remindersMasterList.filter((reminder) => {
        return reminder?.last_name
          ?.toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setReminders(updatedReminders);
    } catch (error) {
      enqueueSnackbar('Something Went Wrong', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  const handleEyeClick = (event, row) => {
    handleOpenEmailReminder();
    setReminderPreview('Please wait...');
    appointmentService.getAppointmentPreview(row.id).then(
      (response) => {
        setReminderPreview(response?.data?.reminderDetail?.htmlBody ?? '');
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content:', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  const handleUpdateReminder = () => {
    showLoading(); // Start showing the loading state/spinner
    appointmentService
      .updateAppointment({
        provider_availability_slots_id: selectedSlotId,
        start_date_time: moment.utc(selectedStartDate),
        end_date_time: moment.utc(selectedEndDate),
        reminder: selectedReminder,
      })
      .then(
        (response) => {
          hideLoading(); // Stop showing the loading state/spinner
          setOpenSettingModal(false);
          setShowRedirectionUpdateModal(true); // The update-specific modal is shown
          setTimeout(() => {
            setShowRedirectionUpdateModal(false);
            setShowFinalModal(true);
            // window.location.reload();
          }, 5000);
        },
        (error) => {
          hideLoading(); // Stop showing the loading state/spinner in case of error
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log('_content', _content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );
  };

  const renderSubFilters = () => {
    if (selectedMainFilter === '1') {
      return (
        <div className={styles.subFilters}>
          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter3"
              className={styles.radioButton}
              name="subfilter"
              value="3"
              checked={selectedSubFilter === '3'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter3">Pending</label>
          </div>

          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter4"
              className={styles.radioButton}
              name="subfilter"
              value="4"
              checked={selectedSubFilter === '4'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter4">Sent</label>
          </div>

          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter5"
              className={styles.radioButton}
              name="subfilter"
              value="5"
              checked={selectedSubFilter === '5'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter5">Cancelled</label>
          </div>

          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter6"
              className={styles.radioButton}
              name="subfilter"
              value="6"
              checked={selectedSubFilter === '6'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter6">All</label>
          </div>
        </div>
      );
    } else if (selectedMainFilter === '2') {
      return (
        <div className={styles.subFilters}>
          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter7"
              className={styles.radioButton}
              name="subfilter"
              value="7"
              checked={selectedSubFilter === '7'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter7">Sent</label>
          </div>

          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter8"
              className={styles.radioButton}
              name="subfilter"
              value="8"
              checked={selectedSubFilter === '8'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter8">Cancelled</label>
          </div>

          <div className={styles.radioOption}>
            <input
              type="radio"
              id="filter9"
              className={styles.radioButton}
              name="subfilter"
              value="9"
              checked={selectedSubFilter === '9'}
              onChange={handleSubFilterChange}
            />
            <label htmlFor="filter9">All</label>
          </div>
        </div>
      );
    }
  };

  const handleSubFilterChange = (event) => {
    setSelectedSubFilter(event.target.value);
  };

  const handleSort = () => {
    // Toggle sort order each time user clicks
    setSortOrder(!sortOrder);

    // Sort reminders
    setReminders((prevState) => {
      return prevState.sort((a, b) => {
        if (moment.utc(a.createdAt).isBefore(moment.utc(b.createdAt))) {
          return sortOrder ? 1 : -1;
        }
        if (moment.utc(b.createdAt).isBefore(moment.utc(a.createdAt))) {
          return sortOrder ? -1 : 1;
        }
        return 0;
      });
    });
  };

  const handleCancelReminder = () => {
    showLoading(); // Start showing the loading state/spinner

    appointmentService
      .cancelAppointment({
        provider_availability_slots_id: selectedSlotId,
        updated_by: selectedCareCoordinator,
        reason: reason,
        openSlot: cancelOpenSlot,
        reminder: selectedReminder,
        selectedAppointmentId: selectedAppointmentId,
      })
      .then(
        () => {
          hideLoading(); // Stop showing the loading state/spinner
          setOpenSettingModal(false);
          setShowRedirectionCancelModal(true);
          setTimeout(() => {
            setShowRedirectionCancelModal(false);
            setShowFinalModal(true);
          }, 5000);
        },
        (error) => {
          hideLoading(); // Stop showing the loading state/spinner in case of error
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log('_content', _content);
          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      );
  };

  const getSurveySentStatus = (row) => {
    if (row.service_group !== 'Therapy') {
      return 'N/A';
    }
    if (row.status === 'cancelled') {
      return 'Cancelled';
    }
    if (row.survey_sent === null) {
      return 'N/A';
    }

    if (row.survey_sent === 1) {
      return 'Sent';
    }

    if (row.survey_cancelled === 1) {
      return 'Cancelled';
    }

    if (row.survey_sent === 0) {
      return (
        <span
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => handleOpenCancelSurvey(row.provider_availability_id)} // Assuming row has provider_availability_id
        >
          Pending
        </span>
      );
    }
  };

  return (
    <div>
      <Modal
        open={showRedirectionCancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            padding: 20,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redirection notice
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The following has just been done:
            <ul>
              <li>Appt reminder has been cancelled</li>
              <li>Dock Tasks have been cancelled</li>
            </ul>
            You will now be shown the cancellation jotform so that you can
            complete the cancellation protocol.
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={showRedirectionUpdateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            padding: 20,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Redirection notice
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The following has just been done:
            <ul>
              <li>Appt reminder has been updated</li>
              <li>
                A new brief email confirming the rescheduled appt date has been
                sent out
              </li>
              <li>
                Dock Tasks have been updated with the new date of the
                rescheduled appt
              </li>
            </ul>
            You will now be shown the cancellation jotform so that you can
            complete the reschedule protocol.
          </Typography>
        </Box>
      </Modal>

      <Modal open={showFinalModal} onClose={() => setShowFinalModal(false)}>
        <div
          style={{ backgroundColor: 'white', padding: '16px', height: '6in' }}
        >
          <Typography variant="h5" gutterBottom>
            Cancellation Form
          </Typography>
          <iframe
            title="jotform"
            src="https://form.jotform.com/220945182806155"
            style={{ width: '100%', height: '70vh', border: 'none' }}
          ></iframe>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#4CAF50' }}
              onClick={() => {
                setShowFinalModal(false);
                window.location.reload();
              }}
            >
              <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                &#10003;
              </span>{' '}
              I have completed the Cancellation Form
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowFinalModal(false);
                window.location.reload();
              }}
            >
              <span style={{ marginRight: '5px', fontWeight: 'bold' }}>X</span>{' '}
              Close - Just Close this Screen
            </Button>
          </div>
        </div>
      </Modal>

      <div className={styles.searchField}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            className={styles.searchFieldInput}
            style={{ marginRight: '20px' }}
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label="Search by Last Name"
            onChange={handleSearch}
          />
        </div>
      </div>

      <div className={styles.filtersContainer}>
        <div
          className={styles.mainFilters}
          style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
        >
          <input
            type="radio"
            id="filter1"
            className={`${styles.radioButton} ${styles.mainFiltersRadioButton}`}
            name="mainfilter"
            value="1"
            checked={selectedMainFilter === '1'}
            onChange={handleMainFilterChange}
          />
          <label htmlFor="filter1">Future Appts</label>
          <input
            type="radio"
            id="filter2"
            className={`${styles.radioButton} ${styles.mainFiltersRadioButton}`}
            name="mainfilter"
            value="2"
            checked={selectedMainFilter === '2'}
            onChange={handleMainFilterChange}
          />
          <label htmlFor="filter2">Past Appts</label>
        </div>

        <div className={styles.subFilters}>{renderSubFilters()}</div>

        {/* Sorting Control for Mobile */}
        {isMobile && (
          <div className={styles.sortContainer}>
            <Button
              variant="outlined"
              onClick={handleSort}
              startIcon={sortOrder ? <ArrowDownward /> : <ArrowUpward />}
            >
              Sort by Date/Time Created
            </Button>
          </div>
        )}
      </div>

      {isMobile ? (
        // Mobile View: Render cards
        <div>
          {reminders
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <ReminderCard
                key={index}
                row={row}
                handleEyeClick={handleEyeClick}
                handleOpenSettingModal={handleOpenSettingModal}
                setSelectedReminder={setSelectedReminder}
                setSelectedAppointmentId={setSelectedAppointmentId}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                setSelectedSlotId={setSelectedSlotId}
                setReason={setReason}
                setIsCancel={setIsCancel}
                selectedMainFilter={selectedMainFilter}
                getSurveySentStatus={getSurveySentStatus} // Pass as prop
                careCoordinators={careCoordinators} // Pass as prop
              />
            ))}
        </div>
      ) : (
        <TableContainer component={Paper} id="child">
          <Table
            sx={{ minWidth: 650 }}
            size="medium"
            aria-label="Reminders_table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Email Status</TableCell>
                <TableCell>Survey Sent</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <Tooltip
                  title="Click to sort by Date/Time Created"
                  placement="top"
                >
                  <TableCell
                    onClick={() => handleSort()}
                    style={{ cursor: 'pointer' }}
                  >
                    Date/Time Created{' '}
                    {sortOrder ? <ArrowDownward /> : <ArrowUpward />}
                  </TableCell>
                </Tooltip>
                <TableCell>Care Coordinator</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Appt Type</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Service Type</TableCell>
                <TableCell>Issue</TableCell>
                <TableCell>Insurance</TableCell>
                <TableCell>Appt Date</TableCell>
                <TableCell>Appt Start Time</TableCell>
                <TableCell>Appt End Time</TableCell>
                <TableCell>
                  {selectedMainFilter === '2'
                    ? 'Preview'
                    : 'Preview | Cancel/Reschedule'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reminders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let emailStatusColor = '';
                  if (row?.email_status === 'pending') {
                    emailStatusColor = '#fff9c4';
                  } else if (row?.email_status === 'Sent') {
                    emailStatusColor = 'lightgreen';
                  } else if (row?.email_status === 'cancelled') {
                    emailStatusColor = '#e85858';
                  }

                  // Color for Survey Sent Status
                  let surveySentStatusColor = '';
                  const surveySentStatus = getSurveySentStatus(row);
                  if (surveySentStatus === 'Pending') {
                    surveySentStatusColor = '#fff9c4';
                  } else if (surveySentStatus === 'Sent') {
                    surveySentStatusColor = 'lightgreen';
                  } else if (surveySentStatus === 'Cancelled') {
                    surveySentStatusColor = '#e85858';
                  }

                  return (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell style={{ background: emailStatusColor }}>
                        {capitalizeFirstLetter(row?.email_status)}
                      </TableCell>

                      <TableCell style={{ background: surveySentStatusColor }}>
                        {getSurveySentStatus(row)}
                      </TableCell>

                      <TableCell>{row?.first_name}</TableCell>
                      <TableCell>{row?.last_name}</TableCell>
                      <TableCell>
                        {moment(row?.createdAt)
                          .local()
                          .format('M/DD/yyyy h:mm:ss a')}
                      </TableCell>

                      <TableCell>
                        {careCoordinators.find(
                          (coordinator) =>
                            coordinator.id === row?.care_coordinator_id
                        )?.username ?? ''}{' '}
                      </TableCell>

                      <TableCell>
                        {' '}
                        {row?.email}{' '}
                        {row?.cc_recipients?.email &&
                        row?.cc_recipients?.email !== ''
                          ? `, ${row.cc_recipients.email}`
                          : ''}
                      </TableCell>

                      <TableCell>
                        {moment(row?.date_of_birth ?? '').format('MM/DD/yyyy')}
                      </TableCell>
                      <TableCell>{row?.appointment_type ?? ''}</TableCell>
                      <TableCell>{row.appointment_location}</TableCell>
                      <TableCell>{row?.provider?.username ?? ''}</TableCell>
                      <TableCell>{row?.service_group ?? ''}</TableCell>
                      <TableCell>
                        {Array.isArray(row.services)
                          ? row.services
                              .map((service) => service.title)
                              .join(', ')
                          : 'No Services'}
                      </TableCell>
                      <TableCell>{row?.insurance}</TableCell>
                      <TableCell>
                        {moment
                          .utc(row?.appointment_start_time)
                          .format('MM/DD/yyyy') ?? ''}
                      </TableCell>
                      <TableCell>
                        {moment.utc(row?.start_date_time).format('h:mm:ss a')}
                      </TableCell>
                      <TableCell>
                        {moment.utc(row?.end_date_time).format('h:mm:ss a')}
                      </TableCell>
                      <TableCell>
                        <RemoveRedEye
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            handleEyeClick(e, row);
                          }}
                        ></RemoveRedEye>
                        {selectedMainFilter !== '2' && (
                          <Create
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedReminder(row);
                              setSelectedAppointmentId(row.appointment_id);
                              setSelectedStartDate(row.start_date_time);
                              setSelectedEndDate(row.end_date_time);
                              setSelectedSlotId(
                                row.provider_availability_slots_id
                              );
                              setReason('');
                              setIsCancel(false);
                              handleOpenSettingModal();
                            }}
                          ></Create>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={reminders.length}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) =>
          setRowsPerPage(parseInt(event.target.value, 25))
        }
        labelRowsPerPage="Rows per page:"
      />

      <Modal
        open={openEmailReminder}
        onClose={handleCloseEmailReminder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '75%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 900,
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: 20,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reminder Preview
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: reminderPreview }}
          ></Typography>
        </Box>
      </Modal>

      <Modal
        open={openSettingModal}
        onClose={handleCloseSettingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '90%' : 800,
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: 20,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Reminder
          </Typography>

          {!isCancel && (
            <>
              <FormControl sx={{ width: 320, margin: 2 }}>
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="frequency"
                  value={selectedDuration}
                  onChange={handleChangeDuration}
                  input={<OutlinedInput label="duration" />}
                  renderValue={(selected) =>
                    selected?.map((v) => durations[v]).join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Object.keys(durations).map((key, idx) => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={selectedDuration.indexOf(key) > -1} />
                      <ListItemText primary={durations[key]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 320, margin: 2 }}>
                <div>
                  <input
                    type="datetime-local"
                    name="appointmentDate"
                    value={moment
                      .utc(selectedStartDate)
                      .format('YYYY-MM-DDTHH:mm')}
                    onChange={(event) => {
                      setSelectedStartDate(event.target.value);
                    }}
                  />
                </div>
              </FormControl>
            </>
          )}

          {isCancel && (
            <>
              <Autocomplete
                disablePortal
                id="users_selection"
                options={adminOrCareCoordinators}
                onChange={onTagsChange}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    name="test"
                    label="Select user"
                  />
                )}
                getOptionLabel={(option) => option.username || ''}
              />
              <TextField
                fullWidth
                required
                autoComplete="off"
                name="reason"
                label="Reason"
                defaultValue=""
                variant="outlined"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                helperText="Please enter the Reason"
              />
            </>
          )}

          {isCancel && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cancelOpenSlot}
                    onChange={(e) => setCancelOpenSlot(!cancelOpenSlot)}
                  />
                }
                label="Open This Slot"
                sx={{ paddingX: 1, paddingY: 1 }}
              />
            </>
          )}

          <div
            style={{ display: 'flex', justifyContent: 'center', margin: 14 }}
          >
            <div style={{ flex: '1' }}></div>
            <div style={{ flex: '2', display: 'flex' }}>
              <div style={{ flex: isCancel ? '3' : '1' }}>
                <Button
                  variant="contained"
                  onClick={async (e) => {
                    setOpenSettingModal(false);
                  }}
                >
                  GO BACK
                </Button>
              </div>

              {!isCancel && (
                <>
                  <div style={{ flex: '1', paddingLeft: '20px' }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'yellow',
                        color: 'black',
                      }}
                      onClick={handleUpdateReminder}
                      disabled={!isRescheduleActive}
                    >
                      Reschedule Appt
                    </Button>
                    <p style={{ fontSize: '12px' }}>
                      Note: Once clicked, it may take 20-30 seconds to process.
                      A spinner will be displayed during this time.
                    </p>
                  </div>
                  {!isRescheduleActive && (
                    <div style={{ flex: '1', paddingLeft: '20px' }}>
                      <p>
                        The Reschedule button is temporarily inactive. Please
                        use the Cancel Button
                      </p>
                    </div>
                  )}
                </>
              )}

              {!isCancel &&
                selectedReminder?.status !== 'cancelled' &&
                selectedReminder?.status !== 'sent' && (
                  <div style={{ flex: '1', paddingLeft: '20px' }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'red',
                      }}
                      onClick={() => {
                        setIsCancel(true);
                      }}
                    >
                      Cancel Appt
                    </Button>
                  </div>
                )}
              {isCancel && (
                <div style={{ flex: '1', paddingLeft: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={disabledButton}
                      onClick={() => {
                        setDisabledButton(true);
                        handleCancelReminder();
                      }}
                    >
                      CANCEL APPT
                    </Button>
                    <p style={{ fontSize: '12px' }}>
                      Note: Once clicked, it may take 20-30 seconds for the
                      operation to complete. A spinner will be displayed during
                      this time. Thank you for your patience.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal/Dialog for Cancelling Survey */}
      <CancelSurveyDialog
        openCancelSurvey={openCancelSurvey}
        handleCloseCancelSurvey={handleCloseCancelSurvey}
        handleCombinedClick={handleCombinedClick}
        selectedProviderAvailabilityId={selectedProviderAvailabilityId}
      />
    </div>
  );
}
