import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  Clear as ClearIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Popover,
  useTheme,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isDoctor } from '../../common/utils/RoleUtils';
import styles from '../../styles/Clients.module.css';

// Below insurances are added and dependency removed from client insurances
const uniquePrimaryInsurances = [
  'Private Pay',
  'Aetna',
  'Aetna EAP',
  'Aetna Medicare Advantage',
  'BCBS',
  'CIGNA',
  'CIGNA EAP',
  'Johns Hopkins EHP',
  'Johns Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare Standard/Indeminity (NOT Prime)',
].sort();

const uniqueSecondaryInsurances = [
  'Private Pay',
  'Aetna',
  'Aetna EAP',
  'Aetna Medicare Advantage',
  'BCBS',
  'CIGNA',
  'CIGNA EAP',
  'Johns Hopkins EHP',
  'Johns Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare Standard/Indeminity (NOT Prime)',
].sort();

const isClientInCustomCategory = (client, category) => {
  switch (category) {
    case 'HRNS':
      return client.STATUS === 'Active' && client.HRNS_CLIENT === 1;
    case 'AWOL':
      return client.STATUS === 'Active' && client.AWOL_CLIENT === 1;
    case 'Agreed Termination':
      return client.STATUS === 'Inactive' && client.AGREED_TERMINATION === 1;
    case 'Remained AWOL':
      return client.STATUS === 'Inactive' && client.REMAINED_AWOL === 1;
    case 'Discharged by Letter':
      return client.STATUS === 'Inactive' && client.DISCHARGED_BY_LETTER === 1;
    case 'Never Showed':
      return client.STATUS === 'Inactive' && client.NEVER_SHOWED === 1;
    // Add other cases as needed
    default:
      return false;
  }
};

export const ClientSidebar = ({
  clients,
  setDisplayedClients,
  activeButton,
  sidebarVisible,
  setSidebarVisible,
  searchAPICall,
  setSearchAPICall,
  LocalLoading,
  allProviders,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedDate, setSelectedDate] = useState(null);
  const [therapistFilter, setTherapistFilter] = useState([]);

  const uniqueTherapists =
    allProviders?.length > 0
      ? [...new Set(allProviders.map((provider) => provider.username))]
      : [];
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [startsWithFirstName, setStartsWithFirstName] = useState(true);
  const [startsWithLastName, setStartsWithLastName] = useState(true);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [secondaryInsuranceFilter, setSecondaryInsuranceFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const [primaryInsuranceFilter, setPrimaryInsuranceFilter] = useState([]);
  const [openPrimaryInsuranceModal, setOpenPrimaryInsuranceModal] =
    useState(false);
  const [openSecondaryInsuranceModal, setOpenSecondaryInsuranceModal] =
    useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [openTherapistPopover, setOpenTherapistPopover] = useState(false);
  const [anchorElTherapist, setAnchorElTherapist] = useState(null);
  const [openStatusPopover, setOpenStatusPopover] = useState(false);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  const isDoctorAdminSupervisor = (user) => {
    return (
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };

  const normalizePhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/[^\d]/g, ''); // Remove any character that is not a digit
  };

  const normalizeDate = (dateString) => {
    const parsedDate = format(new Date(dateString), 'MM/dd/yyyy');
    return parsedDate;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPrimaryInsuranceModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPrimaryInsuranceModal(false);
  };

  const [anchorElSecondary, setAnchorElSecondary] = useState(null);

  const handleClickSecondary = (event) => {
    setAnchorElSecondary(event.currentTarget);
    setOpenSecondaryInsuranceModal(true);
  };

  const handleCloseSecondary = () => {
    setAnchorElSecondary(null);
    setOpenSecondaryInsuranceModal(false);
  };

  const handleClickTherapist = (event) => {
    setOpenTherapistPopover(true);
    setAnchorElTherapist(event.currentTarget);
  };

  const handleCloseTherapist = () => {
    setOpenTherapistPopover(false);
    setAnchorElTherapist(null);
  };

  const handleClickStatus = (event) => {
    setOpenStatusPopover(true);
    setAnchorElStatus(event.currentTarget); // This will be the element the popover is anchored to
  };

  const handleCloseStatus = () => {
    setOpenStatusPopover(false);
    setAnchorElStatus(null); // Reset the anchor element when closing the popover
  };

  const handleStatusChange = (status) => {
    if (statusFilter.includes(status)) {
      // If the status is already in the filter, remove it
      setStatusFilter(statusFilter.filter((s) => s !== status));
    } else {
      // If the status is not in the filter, add it
      setStatusFilter([...statusFilter, status]);
    }
  };

  const filterClients = () => {
    let filtered = clients;

    if (firstName) {
      if (startsWithFirstName) {
        filtered = filtered.filter((client) =>
          client.FIRSTNAME.startsWith(firstName)
        );
      } else {
        filtered = filtered.filter((client) => client.FIRSTNAME === firstName);
      }
    }

    if (lastName) {
      if (startsWithLastName) {
        filtered = filtered.filter((client) =>
          client.LASTNAME.startsWith(lastName)
        );
      } else {
        filtered = filtered.filter((client) => client.LASTNAME === lastName);
      }
    }

    if (email) {
      filtered = filtered.filter((client) => {
        return client.EMAIL.toLowerCase().includes(email.toLowerCase());
      });
    }
    if (phone) {
      filtered = filtered.filter(
        (client) =>
          normalizePhoneNumber(client.PHONE1) === normalizePhoneNumber(phone)
      );
    }

    if (selectedDate) {
      const formattedSelectedDate = format(selectedDate, 'MM/dd/yyyy');
      filtered = filtered.filter(
        (client) => normalizeDate(client.DOB) === formattedSelectedDate
      );
    }
    if (therapistFilter.length > 0) {
      filtered = filtered.filter((client) =>
        therapistFilter.includes(client.PROVIDER)
      );
    }

    if (primaryInsuranceFilter.length > 0) {
      filtered = filtered.filter((client) =>
        primaryInsuranceFilter.includes(client.PRIMINSURANCE)
      );
    }

    if (secondaryInsuranceFilter.length > 0) {
      filtered = filtered.filter((client) =>
        secondaryInsuranceFilter.includes(client.SECINSURANCE)
      );
    }

    if (statusFilter.length > 0) {
      filtered = filtered.filter((client) =>
        statusFilter.some(
          (status) =>
            status === client.STATUS || isClientInCustomCategory(client, status)
        )
      );
    }

    if (accountNumber) {
      filtered = filtered.filter((client) =>
        client.ta_mrn.toLowerCase().startsWith(accountNumber.toLowerCase())
      );
    }

    return filtered;
  };

  useEffect(() => {
    if (!LocalLoading) {
      const filtered = filterClients();
      setDisplayedClients(filtered);
      // Close the popover
      handleCloseTherapist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocalLoading]);

  const handleSearch = () => {
    setSearchAPICall(searchAPICall + 1);
  };

  const handleClear = () => {
    setFirstName(''); // Clear the first name input
    setLastName(''); // Clear the last name input
    setEmail(''); // Clear the email input
    setPhone(''); // Clear the phone input
    setSelectedDate(null); // Reset the date picker
    setTherapistFilter([]); // Clear selected therapists
    setStatusFilter([]); // Clear selected statuses
    setPrimaryInsuranceFilter([]); // Clear selected primary insurances
    setSecondaryInsuranceFilter([]); // Clear selected secondary insurances
    setAccountNumber(''); // Clear the account number input
    setStartsWithFirstName(true); // Reset the checkbox to its initial state
    setStartsWithLastName(true); // Reset the checkbox to its initial state

    setSearchAPICall(false);
  };

  return (
    <div className={!isMobile ? styles.sideBarDiv : ''}>
      <button
        onClick={() => {
          setSidebarVisible(!sidebarVisible);
          document.body.classList.toggle('sidebarExpanded');
          setSearchAPICall(false);
        }}
        style={{ background: 'white', border: 'none' }}
      >
        {/** The below responsiveness is applied using media queries */}
        {sidebarVisible ? (
          <VisibilityOffIcon
            className={styles.visibilityOffIcon}
            fontSize="large"
          />
        ) : (
          <MenuIcon
            className={styles.menuIcon}
            style={{ fill: 'blue' }}
            fontSize="large"
          />
        )}
      </button>

      {sidebarVisible && (
        <div className={`${styles.sidebar} ${styles.sidebarVisible}`}>
          <button
            className={`${styles.sidebarButton} ${styles.sidebarButtonSearch}`}
            onClick={handleSearch}
          >
            <SearchIcon className={styles.icon} /> Search
          </button>
          <button
            className={`${styles.sidebarButton} ${styles.sidebarButtonClear}`}
            onClick={handleClear}
          >
            <ClearIcon className={styles.icon} /> Clear
          </button>

          <div className={`${styles.sidebarInput} ${styles.firstNameInput}`}>
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={startsWithFirstName}
                onChange={(e) => setStartsWithFirstName(e.target.checked)}
                style={{ width: '8%' }}
              />
              <label className={styles.checkboxLabel}>Starts With</label>
            </div>
          </div>

          <div className={styles.sidebarInput}>
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                checked={startsWithLastName}
                onChange={(e) => setStartsWithLastName(e.target.checked)}
                style={{ width: '8%' }}
              />
              <label className={styles.checkboxLabel}>Starts With</label>
            </div>
          </div>

          <div className={`${styles.sidebarInput} ${styles.emailInput}`}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={`${styles.sidebarInput} ${styles.phoneInput}`}>
            <input
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              margin="normal"
              label="Date of Birth"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={setSelectedDate}
              renderInput={(params) => <TextField {...params} />}
              InputLabelProps={{
                style: {
                  color: 'rgba(0, 0, 0, 0.87)',
                  margin: 0,
                  fontSize: '0.875rem',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 400,
                  lineHeight: 1.43,
                  letterSpacing: '0.01071em',
                },
              }}
              style={{ marginTop: '-20px' }}
            />
          </LocalizationProvider>

          {(isDoctorAdminSupervisor(currentUser) &&
            (activeButton === 'All Clients' ||
              activeButton === 'Clients of Supervisees')) ||
          (!isDoctor(currentUser) && activeButton !== 'My Clients') ? (
            <div
              className={`${styles.sidebarInput} ${styles.sidebarInputTherapists}`}
            >
              <label
                onClick={handleClickTherapist}
                className={styles.sideBarInputLabel}
              >
                Therapists <span style={{ cursor: 'pointer' }}>+</span>
              </label>

              {therapistFilter.map((therapist) => (
                <div key={therapist}>{therapist}</div>
              ))}

              {/* Empty line */}
              <div style={{ height: '20px' }}></div>

              <Popover
                open={openTherapistPopover}
                anchorEl={anchorElTherapist}
                onClose={handleCloseTherapist}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{ style: { width: '250px' } }}
              >
                <DialogContent>
                  {uniqueTherapists
                    .sort((a, b) => {
                      return a.localeCompare(b);
                    })
                    .map((therapist) => (
                      <FormControlLabel
                        key={therapist}
                        control={
                          <Checkbox
                            checked={therapistFilter.includes(therapist)}
                            onChange={(e) => {
                              // Update the therapistFilter state based on checkbox changes
                              if (e.target.checked) {
                                setTherapistFilter((prev) => [
                                  ...prev,
                                  therapist,
                                ]);
                              } else {
                                setTherapistFilter((prev) =>
                                  prev.filter((t) => t !== therapist)
                                );
                              }
                            }}
                          />
                        }
                        label={therapist}
                      />
                    ))}
                </DialogContent>
              </Popover>
            </div>
          ) : (
            // Placeholder div
            <div className={styles.therapistsPlaceholder}></div>
          )}

          <div
            className={`${styles.sidebarInput} ${styles.sidebarInputStatus}`}
          >
            <label
              onClick={handleClickStatus}
              className={styles.sideBarInputLabel}
            >
              Status <span style={{ cursor: 'pointer' }}>+</span>
            </label>

            {/* Display selected statuses */}
            {statusFilter.map((status) => (
              <div key={status}>{status}</div>
            ))}

            {/* Empty line */}
            <div style={{ height: '20px' }}></div>

            <Popover
              open={openStatusPopover}
              anchorEl={anchorElStatus}
              onClose={handleCloseStatus}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{ style: { width: '250px' } }} // Adjust the width as needed
            >
              <DialogContent>
                {[
                  'Active',
                  'New',
                  'Prospective',
                  'Inactive',
                  'Never-Showed',
                  'HRNS',
                  'AWOL',
                  'Agreed Termination',
                  'Remained AWOL',
                  'Discharged by Letter',
                  'Never Showed',
                ].map((status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        checked={statusFilter.includes(status)}
                        onChange={() => handleStatusChange(status)}
                      />
                    }
                    label={status}
                  />
                ))}
              </DialogContent>
            </Popover>
          </div>

          <div
            style={{
              marginTop: '-20px',
            }}
          >
            <label onClick={handleClick} className={styles.sideBarInputLabel}>
              Primary Insurance <span style={{ cursor: 'pointer' }}>+</span>
            </label>

            {/* Display selected insurances */}
            {primaryInsuranceFilter.map((insurance) => (
              <div key={insurance}>{insurance}</div>
            ))}

            {/* Empty line */}
            <div style={{ height: '20px' }}></div>

            <Popover
              open={openPrimaryInsuranceModal}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{ style: { width: '250px' } }} // <-- Add this line to control the width
            >
              <DialogContent>
                {uniquePrimaryInsurances.map((insurance) => (
                  <FormControlLabel
                    key={insurance}
                    control={
                      <Checkbox
                        checked={primaryInsuranceFilter.includes(insurance)}
                        onChange={() => {
                          if (primaryInsuranceFilter.includes(insurance)) {
                            setPrimaryInsuranceFilter((prev) =>
                              prev.filter((i) => i !== insurance)
                            );
                          } else {
                            setPrimaryInsuranceFilter((prev) => [
                              ...prev,
                              insurance,
                            ]);
                          }
                        }}
                      />
                    }
                    label={insurance}
                  />
                ))}
              </DialogContent>
            </Popover>
          </div>

          <div
            style={{
              marginTop: '-20px',
            }}
          >
            <label
              onClick={handleClickSecondary}
              className={styles.sideBarInputLabel}
            >
              Secondary Insurance <span style={{ cursor: 'pointer' }}>+</span>
            </label>

            {/* Display selected insurances */}
            {secondaryInsuranceFilter.map((insurance) => (
              <div key={insurance}>{insurance}</div>
            ))}

            {/* Empty line */}
            <div style={{ height: '20px' }}></div>
            <Popover
              open={openSecondaryInsuranceModal}
              anchorEl={anchorElSecondary}
              onClose={handleCloseSecondary}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{ style: { width: '250px' } }} // Control the width of the popover
            >
              <DialogContent>
                {uniqueSecondaryInsurances.map((insurance) => (
                  <FormControlLabel
                    key={insurance}
                    control={
                      <Checkbox
                        checked={secondaryInsuranceFilter.includes(insurance)}
                        onChange={() => {
                          if (secondaryInsuranceFilter.includes(insurance)) {
                            setSecondaryInsuranceFilter((prev) =>
                              prev.filter((i) => i !== insurance)
                            );
                          } else {
                            setSecondaryInsuranceFilter((prev) => [
                              ...prev,
                              insurance,
                            ]);
                          }
                        }}
                      />
                    }
                    label={insurance}
                  />
                ))}
              </DialogContent>
            </Popover>
          </div>

          <div className={styles.sidebarInput}>
            <input
              type="text"
              placeholder="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className={styles.sidebarInput}
            />
          </div>
        </div>
      )}
    </div>
  );
};
