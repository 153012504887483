import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveFormData } from '../../actions/scheduling';
import EventBus from '../../common/EventBus';
import { providerService } from '../../services';
import {
  Grid2,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import styles from '../../styles/SchedulingStep04.module.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};
const insuranceList = [
  'Aetna',
  'BCBS',
  'CIGNA',
  'Hopkins EHP',
  'Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare',
  'Other',
];

const externalTherapists = [
  { name: 'Caitlin Cordial, LCPC' },
  { name: 'Eileen Griffin, Psy.D.' },
  { name: 'Carmella Katz, LCSW-C' },
  { name: 'Kelly Kirckpatrick, LCSW-C' },
  { name: 'Emalee Quickel, Ph.D.' },
  { name: 'Benn Rutt, Ph.D.' },
];

const healthcareProvidersOptions = [
  { name: 'Sheila Bennett (Midwife)' },
  { name: 'Laura Bothe, NP' },
  { name: 'Blanche Kattie, NP' },
  { name: 'Dr. Yaniv Berger (Internist)' },
  { name: 'Dr. Francisca Bruney (Family Medicine)' },
  { name: 'Dr. Makeida Koyi (Family Medicine)' },
  { name: 'Dr. Steven Miller (Internist)' },
  { name: 'Dr. Munachim Uyanwune (Psychiatrist)' },
  { name: 'Other' },
];

const SchedulingStep04 = (props) => {
  const state = useSelector((state) => state.scheduling);
  const dispatch = useDispatch();
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [selectedProviders, setSelectedProviders] = React.useState([]);

  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedTherapist, setSelectedTherapist] = useState('');
  const [selectedExternalTherapist, setSelectedExternalTherapist] =
    useState('');
  const [selectedHealthcareProvider, setSelectedHealthcareProvider] =
    useState('');

  useEffect(() => {
    providerService.getProviders().then(
      (response) => {
        let providers = response?.data?.providers ?? [];
        providers = providers.sort((user1, user2) => {
          const name1 = user1?.username?.split(' ')[1] ?? '';
          const name2 = user2?.username?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });
        // The above filtering providers from users and setting providers.

        setProvidersOptions(providers);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log('_content ', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProviders(typeof value === 'string' ? value.split(',') : value);
    let clone = { ...props.step04FormValues };
    clone.selectedProviders =
      typeof value === 'string' ? value.split(',') : value;
    props.setStep04FormValues(clone);
  };
  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        <Grid2 container spacing={3} style={{ marginTop: '-115px' }}>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                How did they hear about us?
              </InputLabel>
              <Select
                error={
                  props !== null &&
                  props.step04FormValues !== null &&
                  typeof props.step04FormValues.aboutUsInfo === 'string' &&
                  props.step04FormValues.aboutUsInfo.length > 0
                    ? false
                    : true
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  props.step04FormValues
                    ? props.step04FormValues.aboutUsInfo
                    : ''
                }
                label="How did they hear about us?"
                onChange={(event) => {
                  let clone = Object.assign({}, props.step04FormValues);
                  clone.aboutUsInfo = event.target.value;
                  props.setStep04FormValues(clone);
                  state.aboutUsInfo = event.target.value;
                  dispatch(saveFormData(state));
                }}
              >
                <MenuItem value={'N/A'}>N/A</MenuItem>
                <MenuItem value={'Google'}>Google</MenuItem>
                <MenuItem value={'Psychcare Client'}>Psychcare Client</MenuItem>
                <MenuItem value={'PsychCare Therapist'}>
                  PsychCare Therapist
                </MenuItem>
                <MenuItem value={'External Therapist'}>
                  External Therapist
                </MenuItem>
                <MenuItem value={'Other Healthcare Provider'}>
                  Other Healthcare Provider
                </MenuItem>
                <MenuItem value={'Psychcare Website'}>
                  Psychcare Website
                </MenuItem>
                <MenuItem value={'Hopkins - Bayview'}>
                  Hopkins - Bayview
                </MenuItem>
                <MenuItem value={'Insurance List'}>Insurance List</MenuItem>
                <MenuItem value={'Psychology Today'}>Psychology Today</MenuItem>
                <MenuItem value={'Where What When Magazine'}>
                  Where What When Magazine
                </MenuItem>
                <MenuItem value={'Word of Mouth'}>Word of Mouth</MenuItem>
                <MenuItem value={'Other Please specify'}>
                  Other Please specify
                </MenuItem>
              </Select>
            </FormControl>
          </Grid2>
          {(props.step04FormValues.aboutUsInfo === 'PsychCare Therapist' ||
            props.step04FormValues?.aboutUsInfo === 'External Therapist' ||
            props.step04FormValues.aboutUsInfo ===
              'Other Healthcare Provider' ||
            props.step04FormValues.aboutUsInfo === 'Word of Mouth' ||
            props.step04FormValues.aboutUsInfo === 'Insurance List' ||
            props.step04FormValues.aboutUsInfo === 'Other Please specify') && (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
              {props.step04FormValues.aboutUsInfo === 'Insurance List' ? (
                <React.Fragment>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="insurance-select-label">
                      Which one?
                    </InputLabel>
                    <Select
                      name="selectedInsurance" // This must match the key used in your state
                      labelId="insurance-select-label"
                      id="insurance-select"
                      value={selectedInsurance}
                      error={
                        props.step04FormValues.aboutUsInfo ===
                          'Insurance List' &&
                        !props.step04FormValues.selectedInsurance
                      }
                      onChange={(event) => {
                        const { name, value } = event.target; // Ensure that your form elements have the correct 'name' attributes
                        let clone = Object.assign({}, props.step04FormValues);

                        // Update the correct property based on the input's name attribute
                        clone[name] = value;

                        // If handling insurance specifically
                        if (name === 'selectedInsurance') {
                          setSelectedInsurance(value); // Update local state if used
                        }

                        // Update the parent component's state
                        props.setStep04FormValues(clone);
                      }}
                    >
                      {insuranceList.map((insurance, index) => (
                        <MenuItem key={index} value={insurance}>
                          {insurance}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedInsurance === 'Other' && (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      variant="outlined"
                      id="other_insurance"
                      label="Please specify"
                      error={
                        props.step04FormValues.selectedInsurance === 'Other' &&
                        !props.step04FormValues.otherInsurance
                      }
                      onChange={(event) => {
                        let clone = Object.assign({}, props.step04FormValues);
                        clone.otherInsurance = event.target.value;
                        props.setStep04FormValues(clone);
                      }}
                    />
                  )}
                </React.Fragment>
              ) : props.step04FormValues.aboutUsInfo ===
                'PsychCare Therapist' ? (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="therapist-select-label">
                    Which one?
                  </InputLabel>
                  <Select
                    labelId="therapist-select-label"
                    id="therapist-select"
                    value={selectedTherapist}
                    onChange={(event) => {
                      let clone = Object.assign({}, props.step04FormValues);
                      clone.which_one = event.target.value;
                      setSelectedTherapist(event.target.value);
                      props.setStep04FormValues(clone);
                    }}
                  >
                    {providersOptions.map((provider, index) => (
                      <MenuItem key={index} value={provider.provider_name}>
                        {provider.provider_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : props.step04FormValues.aboutUsInfo ===
                'External Therapist' ? (
                <React.Fragment>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="external-therapist-select-label">
                      Which External Therapist?
                    </InputLabel>
                    <Select
                      labelId="external-therapist-select-label"
                      id="external-therapist-select"
                      value={selectedExternalTherapist}
                      error={
                        props.step04FormValues.aboutUsInfo ===
                          'External Therapist' &&
                        !props.step04FormValues.selectedExternalTherapist
                      }
                      onChange={(event) => {
                        let clone = Object.assign({}, props.step04FormValues);
                        clone.selectedExternalTherapist = event.target.value;
                        props.setStep04FormValues(clone);
                        setSelectedExternalTherapist(event.target.value);
                      }}
                    >
                      {externalTherapists.map((therapist, index) => (
                        <MenuItem key={index} value={therapist.name}>
                          {therapist.name}
                        </MenuItem>
                      ))}
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                  {/* Conditional text field for specifying other external therapists */}
                  {selectedExternalTherapist === 'Other' && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="other_external_therapist"
                      label="Please specify"
                      error={
                        props.step04FormValues.selectedExternalTherapist ===
                          'Other' &&
                        !props.step04FormValues.otherExternalTherapist
                      }
                      onChange={(event) => {
                        let clone = Object.assign({}, props.step04FormValues);
                        clone.otherExternalTherapist = event.target.value;
                        props.setStep04FormValues(clone);
                      }}
                    />
                  )}
                </React.Fragment>
              ) : props.step04FormValues.aboutUsInfo ===
                'Other Healthcare Provider' ? (
                <React.Fragment>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="healthcare-provider-select-label">
                      Which one?
                    </InputLabel>
                    <Select
                      labelId="healthcare-provider-select-label"
                      id="healthcare-provider-select"
                      value={selectedHealthcareProvider}
                      onChange={(event) => {
                        let clone = Object.assign({}, props.step04FormValues);
                        clone.which_one = event.target.value;
                        setSelectedHealthcareProvider(event.target.value);
                        props.setStep04FormValues(clone);
                      }}
                    >
                      {healthcareProvidersOptions.map((provider, index) => (
                        <MenuItem key={index} value={provider.name}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedHealthcareProvider === 'Other' && (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      variant="outlined"
                      id="other_healthcare_provider"
                      label="Please specify"
                      onChange={(event) => {
                        let clone = Object.assign({}, props.step04FormValues);
                        clone.which_one = event.target.value;
                        props.setStep04FormValues(clone);
                      }}
                    />
                  )}
                </React.Fragment>
              ) : (
                <TextField
                  autoComplete="new-password"
                  fullWidth
                  variant="outlined"
                  id="which_one"
                  label={
                    props.step04FormValues.aboutUsInfo === 'Word of Mouth' ||
                    props.step04FormValues.aboutUsInfo ===
                      'Other Please specify'
                      ? 'Please specify'
                      : 'Which one?'
                  }
                  onChange={(event) => {
                    let clone = Object.assign({}, props.step04FormValues);
                    clone.which_one = event.target.value;
                    props.setStep04FormValues(clone);
                  }}
                />
              )}
            </Grid2>
          )}

          <Grid2 size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label">
                What provider(s) did the client ask for?
              </InputLabel>
              <Select
                multiple
                labelId="demo-multiple-checkbox-label"
                name="providers"
                value={selectedProviders}
                onChange={handleChange}
                input={<OutlinedInput label="Providers" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {providersOptions.map((obj) => (
                  <MenuItem key={obj.provider_name} value={obj.provider_name}>
                    <Checkbox
                      checked={
                        selectedProviders.findIndex((masterObj) => {
                          return masterObj === obj.provider_name;
                        }) > -1
                      }
                    />
                    <ListItemText primary={obj.provider_name} />
                  </MenuItem>
                ))}
              </Select>
              {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
            </FormControl>
          </Grid2>
        </Grid2>
      </div>
    </React.Fragment>
  );
};

export default memo(SchedulingStep04);
