import React, { useState, useEffect } from 'react';
import { Box, TableContainer, Switch, FormControlLabel } from '@mui/material';
import ResponsiveTabs from '../UI/ResponsiveTabs';
import { useMediaQuery, useTheme } from '@mui/material';
import AdminGeneralTable from './AdminGeneralTable';
import AdminPracticeInfoTable from './AdminPracticeInfoTable';
import styles from '../../styles/ProviderMetrics.module.css'; // Adjust the path and filename as needed

export default function Admin() {
  const [showActiveAdmins, setShowActiveAdmins] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Component for tab labels with descriptions
  const TabLabelWithDescription = ({ title, description }) => (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: isSmallScreen ? '1.25rem' : '1rem' }}>
        {title}
      </div>
      <div className={styles.tabDescription}>{description}</div>
    </div>
  );

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');
    setActiveTab(newValue);
  };

  // Define the tabs array similar to the Provider component
  const tabs = [
    {
      index: 0,
      title: 'Personal Info',
      label: (
        <TabLabelWithDescription
          title="Personal Info"
          description="Name, Email, DOB, etc"
        />
      ),
      className:
        activeTab === 0 ? styles.customTabSelected : styles.customTabRoot,
    },
    {
      index: 1,
      title: 'Admin Practice Info',
      label: (
        <TabLabelWithDescription
          title="Admin Practice Info"
          description="HIPAA Workforce, LLC Info, etc"
        />
      ),
      className:
        activeTab === 1 ? styles.customTabSelected : styles.customTabRoot,
    },
  ];

  // No conditional tabs in this case, but keeping the structure consistent
  const visibleTabs = tabs.filter((tab) => {
    if (tab.condition === undefined) return true;
    return tab.condition;
  });

  // Apply styles to each tab
  visibleTabs.forEach((tab, idx) => {
    tab.className = `${styles.customTabRoot} ${
      activeTab === idx ? styles.customTabSelected : ''
    }`;
  });

  // Ensure activeTab is within bounds
  useEffect(() => {
    if (activeTab >= visibleTabs.length) {
      setActiveTab(0);
    }
  }, [visibleTabs.length, activeTab]);

  // Function to render the content based on the actual tab index
  const renderActiveTabContent = () => {
    const actualTabIndex = visibleTabs[activeTab]?.index;
    switch (actualTabIndex) {
      case 0:
        return <AdminGeneralTable showActiveAdmins={showActiveAdmins} />;
      case 1:
        return <AdminPracticeInfoTable showActiveAdmins={showActiveAdmins} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Tabs Component */}
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          width: '100%',
          marginTop: isSmallScreen ? '56px' : '75px', // Adjust margin for mobile
        }}
      >
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            padding: isSmallScreen ? '0 8px' : '0', // Adjust padding for mobile
          }}
        >
          <ResponsiveTabs
            tabs={visibleTabs}
            activeTab={activeTab}
            onChange={handleTabChange}
          />
        </Box>
      </div>

      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showActiveAdmins}
                onChange={() => setShowActiveAdmins(!showActiveAdmins)}
                name="showActiveAdmins"
              />
            }
            label={showActiveAdmins ? 'Active Admins' : 'Inactive Admins'}
            style={{ margin: 0 }}
          />
        </div>
      </TableContainer>

      {renderActiveTabContent()}
    </div>
  );
}
