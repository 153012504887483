import React, { useCallback, useEffect, memo, useState } from 'react';
import UserService from '../../services/user.service';
import {
  Grid2,
  TextField,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
  Box,
  Slider,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import EventBus from '../../common/EventBus';
import {
  Lock as LockIcon,
  History as HistoryIcon,
  LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import styles from '../../styles/ProviderConfig.module.css';
import { createConfirmation } from 'react-confirm';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import {
  isAdmin,
  isDoctor,
  isSupervisee,
  isOnlyDoctorOrSupervisor,
  isAdminOrCareCoordinator,
  isSupervisorORSupervisee,
} from '../../common/utils/RoleUtils';
import { socket } from '../../common/sockets';
import {
  TestingConfigDialog,
  TestingReasonDialog,
  TestingHistoryDialog,
  SubPopulationDialog,
  ConfirmDialog,
} from '../Dialogs';
import { providerService, notificationService } from '../../services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const testingConfigRequiredFields = [
  'locations',
  'status',
  'insurances',
  'Adoption Evaluations',
  'Adult Competency',
  'Child Abuse',
  'Child Custody Evaluations',
  'Criminal Mitigation',
  'Criminal Responsibility',
  'Expert Witness Testimony and Evaluations',
  'Fitness for Duty Evaluation',
  'Immigration Evaluation:  Disability Waiver',
  'Immigration Evaluation: Asylum Cases',
  'Immigration Evaluation: Extreme or Exceptional Hardship',
  'Immigration Evaluation: Violence Against Women Act',
  'Juvenile Competency',
  'Law Enforcement/Weapon Screening',
  'Medical Records Review',
  'Parenting Capacity Evaluations',
  'Risk Assessments- Violence/Sex',
  'Sentencing Evaluations',
  'Sex Crimes',
  'White Collar Crime',
  '504 accommodations',
  'ADHD Adult Diagnostic Evaluation',
  'ADHD Child Comprehensive Evaluation',
  'ADHD/Autism Child Comprehensive Combined Evaluation',
  'Anxiety',
  'Autism Spectrum Disorder - Under 18 Evaluation',
  'Autism Spectrum Disorder - Adult Evaluation',
  'Bariatric Surgical Psychological Evaluations',
  // 'Comprehensive Evaluation',
  'Child Psycho-Educational Evaluation',
  'Cosmetic Surgery Evaluations',
  'Depression',
  'Differential Adult Diagnosis Evaluation',
  'Developmental delays and early intervention',
  'Evaluation for Accommodations on High Stakes Testing',
  'Giftedness',
  'Intellectual disabilities',
  'Kindergarten readiness and early entry into kindergarten',
  'Neuropsychological Testing ',
  'Nonverbal learning disabilities',
  'Oppositional defiance and conduct disorders',
  'Reading',
  'Writing',
  'Math Disability',
  'Special education services',
  'Spinal Stimulator Pre Surgery Evaluations',
  'Transgender Evaluations',
  'Transplant / Bone Marrow Transplant Pre-Surgical',
  'Weaknesses in executive functioning',
  'Weaknesses in memory and learning',
  'testing_survey',
];

const StatusShowingComponentSupervisee = ({ currentStatus }) => {
  return (
    <>
      <Typography
        component="span"
        sx={{
          background: '#e0e0e0', // label-like background color
          padding: '4px 8px', // padding inside the label
          borderRadius: '4px', // rounded corners for the label
          display: 'inline-block',
          marginRight: '26px', // space before the button
        }}
      >
        <b>Status:</b>
        {currentStatus.status}
      </Typography>

      <IconButton
        onClick={(event) => event.stopPropagation()} // Prevents the accordion from expanding or collapsing on icon click
        edge="end"
      >
        {currentStatus.lockState === 'locked' ? (
          <LockIcon color={currentStatus.lockColor} />
        ) : (
          <LockOpenIcon color={currentStatus.lockColor} />
        )}
      </IconButton>
    </>
  );
};

const HistoryShowingComponentSuperivsee = ({
  question,
  handleOpenHistory,
  formValues,
}) => {
  let shouldShow = false;
  const supervisionInitialModifications =
    formValues?.supervision?.supervision_initial_modifications;
  const supervisionToAndFroModifications =
    formValues?.supervision?.modifications_history;

  // Loop through supervisionInitialModifications
  for (let i = 0; i < supervisionInitialModifications?.length; i++) {
    const currentObject = supervisionInitialModifications[i];
    // Loop through keys of the current object
    for (const key in currentObject) {
      if (key.startsWith('area')) {
        const areaValue = currentObject[key];
        // Check if the areaValue contains the question
        if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
          shouldShow = true;
          break;
        }
      }
    }
    if (shouldShow) break; // Exit the loop if the condition is met
  }

  for (let i = 0; i < supervisionToAndFroModifications?.length; i++) {
    const currentObject = supervisionToAndFroModifications[i];
    // Loop through keys of the current object
    for (const key in currentObject) {
      if (key.startsWith('area')) {
        const areaValue = currentObject[key];
        // Check if the areaValue contains the question
        if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
          shouldShow = true;
          break;
        }
      }
    }
    if (shouldShow) break; // Exit the loop if the condition is met
  }

  // Return the appropriate component based on shouldShow
  if (shouldShow) {
    return (
      <IconButton onClick={handleOpenHistory}>
        <HistoryIcon />
      </IconButton>
    );
  } else {
    return <></>;
  }
};

const commonOptions = ['Yes', 'No', 'Yes, But...'];
const defaultValues = {
  locations: [],
  status: [],
  insurances: [],
  subPopulations: [], // Add this line

  'Parenting Therapy': [],

  'DBT Therapy - DBT Certified Therapist': [],
  'DBT Therapy - Primarily Use DBT in Therapy': [],
  'DBT Therapy - Occasionally use DBT Skills when appropriate': [],
  'Feminist Therapy': [],
  'Pre-Marital Counseling': [],
  Depression: [],
  'Developmental Disorders': [],
  'Dissociative Identity (DID)': [],
  'Domestic Violence (Current)': [],
  'Domestic Violence (Past)': [],
  'Drug Abuse (as primary issue)': [],

  'Eating Disorder: Anorexia/Bulimia (Mild-Moderate), as #1 Therapy Issue': [],
  'Eating Disorder: Anorexia/Bulimia (Moderate-Severe), as #1 Therapy Issue':
    [],
  'Eating Disorder: Anorexia/Bulimia, as #2 Therapy Issue': [],
  'Eating Disorder: Binge Eating Disorder': [],

  'Family Conflict': [],

  'Incest - Perpetrator': [],
  'Incest - Victim': [],
  'Infidelity - Perpetrator': [],
  'Infidelity - Victim': [],

  'Internet Addiction': [],
  'Learning Disability': [],
  'LGBTQIAA+ (issues; identity development, coping with oppression)': [],

  'Mental Retardation (Treatment)': [],
  'Narcissistic Personality Disorder': [],
  'Obesity (Presenting Issue)': [],

  'Oppositional Defiance': [],

  'Peer Relationships': [],
  'Post Partum Depression': [],
  'Relationship Issues (General)': [],
  'Relationships - Open Relationships': [],

  'Sexual Abuse (Presenting Issue)': [],
  'Sexual Abuse in Military (MST)': [],
  'Sexual Addiction': [],
  'Sexuality - BDSM': [],
  'Sleep or Insomnia': [],

  'Substance Abuse': [],

  'Transgender/Sex Surgery - Writing Letter of Recommendation': [],
  'Trauma and PTSD - Childhood': [],
  'Trauma and PTSD - General': [],
  'Traumatic Brain Injury': [],
  Trichotillomania: [],
  'Video Game Addiction': [],

  'Applied Behavioral Analysis': [],

  'Emotionally Focused Therapy (EFT)': [],
  // "Existential": [],
  'Experiential Therapy': [],
  'Expressive Arts': [],
  'Exposure and Response Prevention (ERP)': [],

  'Internal Family Systems (IFS)': [],

  'Mindfulness-based (MBCT)': [],
  'Motivational Interviewing': [],

  'Neuro-Linguistic': [],

  'Parent-Child Interaction Therapy (PCIT)': [],
  'Person-Centered': [],

  'Positive Psychology': [],
  'Prolonged Exposure Therapy': [],

  'Rational Emotive Behavior Therapy (REBT)': [],

  'Solution Focused Brief (SFBT)': [],

  'Structural Family Therapy': [],
  therapy_type: [],
  specialized_therapies: [],
  therapy_issues: [],
  therapy_modalities: [],
  populationValue: [20, 40],
  '504 accommodations': [],
  'ADHD Adult Diagnostic Evaluation': ['No'],
  'ADHD Child Comprehensive Evaluation': ['No'],
  'ADHD/Autism Child Comprehensive Combined Evaluation': ['No'],
  Anxiety: [],
  'Autism Spectrum Disorder - Under 18 Evaluation': ['No'],
  'Autism Spectrum Disorder - Adult Evaluation': ['No'],
  'Bariatric Surgical Psychological Evaluations': [],
  // 'Comprehensive Evaluation': ['No'],
  'Child Psycho-Educational Evaluation:': ['No'],
  'Cosmetic Surgery Evaluations': [],
  'Developmental delays and early intervention': [],
  'Differential Adult Diagnosis Evaluation': ['No'],
  'Evaluation for Accommodations on High Stakes Testing': [],
  Giftedness: [],
  'Intellectual disabilities': [],
  'Kindergarten readiness and early entry into kindergarten': [],
  'Neuropsychological Testing ': [],
  'Nonverbal learning disabilities': [],
  'Oppositional defiance and conduct disorders': [],
  Reading: [],
  Writing: [],
  'Math Disability': [],
  'Special education services': [],
  'Spinal Stimulator Pre Surgery Evaluations': [],
  'Transgender Evaluations': [],
  'Transplant / Bone Marrow Transplant Pre-Surgical': [],
  'Weaknesses in executive functioning': [],
  'Weaknesses in memory and learning': [],

  'Adoption Evaluations': [],
  'Adult Competency': [],
  'Child Abuse': [],
  'Child Custody Evaluations': [],
  'Criminal Mitigation': [],
  'Criminal Responsibility': [],
  'Expert Witness Testimony and Evaluations': [],
  'Fitness for Duty Evaluation': [],
  'Immigration Evaluation:  Disability Waiver': [],
  'Immigration Evaluation: Asylum Cases': [],
  'Immigration Evaluation: Extreme or Exceptional Hardship': [],
  'Immigration Evaluation: Violence Against Women Act': [],
  'Juvenile Competency': [],
  'Law Enforcement/Weapon Screening': [],
  'Medical Records Review': [],
  'Parenting Capacity Evaluations': [],
  'Risk Assessments- Violence/Sex': [],
  'Sentencing Evaluations': [],
  'Sex Crimes': [],
  'White Collar Crime': [],
  testing_survey: '',
};

const TestingConfig = (props) => {
  const [triggerCallForSupervisee, setTriggerCallForSupervisee] = useState();
  const { loading } = useSelector((state) => state.loading); // getting loading from redux state

  const [reviewState, setReviewState] = useState({
    statusIndex: 0,
    statuses: [
      {
        status: 'Initial Entry',
        lockColor: 'success',
        lockState: 'unlocked',
      },
      {
        status: 'Initial Entry Submitted for Review',
        lockColor: 'warning',
        lockState: 'locked',
      },
      {
        status: 'Initial Entry Reviewed & Approved',
        lockColor: 'error',
        lockState: 'locked',
      },
      {
        status: 'Modification Requested',
        lockColor: 'warning',
        lockState: 'locked',
      },
      {
        status: 'Modification Reviewed & Approved',
        lockColor: 'error',
        lockState: 'locked',
      },
    ],
  });
  // The below state is use for checking ig the selected user is supervisee
  const [isSelectedUserSupervisee, setIsSelectedUserSupervisee] =
    useState(false);

  // The below state is for capturing supervisor reasons for changing
  const [supervisorReasons, setSupervisorReasons] = useState([]);
  const [changeReason, setChangeReason] = useState('');
  // This below useState is for tracking the previous value which is getting changed
  const [previousValue, setPreviousValue] = useState('');
  // This below useState is for tracking the currentValue which has been changed
  const [currentValue, setCurrentValue] = useState('');

  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);

  // The below useState is for recording the areaOfChange for supervisor
  const [areaOfChange, setAreaOfChange] = useState();

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const currentStatus = reviewState.statuses[reviewState.statusIndex];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState('panel1');
  const [subPopulations, setSubPopulations] = useState([]);
  const [populationValue] = React.useState(defaultValues.populationValue);
  const [toDelete, setToDelete] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeAccordion = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [open, setOpen] = React.useState(false);

  const handleCloseHistory = () => {
    setHistoryDialogOpen(false);
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleOpenHistory = (question) => {
    const supervisionInitialModifications =
      formValues?.supervision?.supervision_initial_modifications;

    const supervisionToAndFroModitications =
      formValues?.supervision?.modifications_history;

    let valuesToBePushed = []; // Values to be pushed to the history Object

    for (let i = 0; i < supervisionInitialModifications?.length; i++) {
      const propertyName = 'area' + i;
      const areaValue = supervisionInitialModifications[i][propertyName];
      const reasonValue = supervisionInitialModifications[i]['reason'];

      if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
        valuesToBePushed.push({
          area: areaValue,
          reason: reasonValue,
          date: supervisionInitialModifications[i].date,
          currentValue: supervisionInitialModifications[i].currentValue,
          previousValue: supervisionInitialModifications[i].previousValue,
          isSelectedUserSupervisee:
            supervisionInitialModifications[i].isSelectedUserSupervisee,
        });
        setHistoryData([...valuesToBePushed]);
        break;
      }
    }

    // The below is the case for supervision To and Fro
    for (let i = 0; i < supervisionToAndFroModitications?.length; i++) {
      const currentObject = supervisionToAndFroModitications[i];
      for (const key in currentObject) {
        if (key.startsWith('area')) {
          const areaValue = currentObject[key];
          const reasonValue = currentObject['reason'];

          if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
            valuesToBePushed.push({
              date: supervisionToAndFroModitications[i].date,
              currentValue: supervisionToAndFroModitications[i].currentValue,
              previousValue: supervisionToAndFroModitications[i].previousValue,
              area: areaValue,
              reason: reasonValue,
              isSelectedUserSupervisee:
                supervisionToAndFroModitications[i].isSelectedUserSupervisee,
            });
            setHistoryData([...valuesToBePushed]);
            break;
          }
        }
      }
    }
    setHistoryDialogOpen(true);
  };

  const handleClose = (save = false) => {
    setOpen(false);
    if (!save) {
      let formValuesClone = { ...formValues };
      delete formValuesClone[latestField];
      setReason(null);
      setFormValues(formValuesClone);
    }
    // The below is the case for handling the supervisor filling out for supervisee
    if (isSelectedUserSupervisee) {
      setReasonDialogOpen(true);
    }
  };
  const [providerConfigOption, setProviderConfigOption] = React.useState({});
  const { user: currentUser } = useSelector((state) => state.auth);

  // Below I am comparing if currentUser is supervisee
  if (
    isSupervisee(currentUser) ||
    props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
      'Non-Fully Licensed'
    )
  ) {
    defaultValues.supervision = {
      initial_request: false,
      date_of_initial_request: '',
      initial_approval: false,
      date_of_initial_approval: '',
      supervision_initial_modifications: {
        area1: 'reason for modification',
        area2: 'reason for modification',
      },
      modification_request: false,
      modification_approval: false,
      modifications_history: [],
      latest_modifications: [], // This is the latest modification array introduced to show the modification
    };
  }

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [reason, setReason] = React.useState();
  const [selectedField, setSelectedField] = React.useState();
  const [latestField, setLatestField] = React.useState(null);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [changes, setChanges] = React.useState({});

  const confirm = createConfirmation(ConfirmDialog);
  const addChangeNameValsToLocalStorage = (nameVals) => {
    let localStorageChangedFormValues = localStorage.getItem(
      'changedFormValuesTesting'
    );
    if (localStorageChangedFormValues) {
      let changedFormValuesStored = JSON.parse(localStorageChangedFormValues);
      if (changedFormValuesStored[props?.selectedUser?.id]) {
        changedFormValuesStored[props?.selectedUser?.id] = {
          ...changedFormValuesStored[props.selectedUser.id],
          ...nameVals,
        };
      } else {
        changedFormValuesStored[props?.selectedUser?.id] = { ...nameVals };
      }
      localStorage.setItem(
        'changedFormValuesTesting',
        JSON.stringify(changedFormValuesStored)
      );
    } else {
      localStorage.setItem(
        'changedFormValuesTesting',
        JSON.stringify({ [props?.selectedUser?.id]: { ...nameVals } })
      );
    }
  };
  const purgeChangeFromLocalStorage = (selectedUserId) => {
    let localStorageChangedFormValues = localStorage.getItem(
      'changedFormValuesTesting'
    );
    if (localStorageChangedFormValues) {
      let changedFormValuesStored = JSON.parse(localStorageChangedFormValues);
      if (changedFormValuesStored[selectedUserId]) {
        delete changedFormValuesStored[selectedUserId];
        localStorage.setItem(
          'changedFormValuesTesting',
          JSON.stringify(changedFormValuesStored)
        );
      }
    }
  };
  const getChangesFromLocalStorage = () => {
    const localChanges = localStorage.getItem('changedFormValuesTesting');
    if (localChanges) {
      return JSON.parse(localChanges)[props?.selectedUser?.id];
    }
    return {};
  };

  const areAdminFieldsComplete = () => {
    return (
      formValues['locations']?.length > 0 &&
      formValues['status']?.length > 0 &&
      formValues['insurances']?.length > 0
    );
  };

  const handlePopulationChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      populationValue: newValue,
    });
    setChanges({ ...changes, populationValue: newValue });

    // The below condition checks if selectedUser is supervisee then save the area of change
    if (
      isSelectedUserSupervisee ||
      (isSupervisee(currentUser) && formValues?.supervision?.initial_approval)
    ) {
      setReasonDialogOpen(true); //opening the reasons dialog
      setAreaOfChange('populationValue');

      //setting previous and current values here
      setPreviousValue(formValues.populationValue);
      setCurrentValue(newValue);
    }
  };

  const handleSubPopulationChange = (index) => (event, newValue) => {
    if ((formValues?.subPopulations?.length || 0) === 0) {
      let tempPopulation = [];
      tempPopulation.push({ value: newValue });
      setChanges((prevChanges) => ({
        ...prevChanges,
        subPopulations: tempPopulation,
      }));
      setFormValues({
        ...formValues,
        subPopulations: tempPopulation,
      });
    } else {
      if (index <= formValues?.subPopulations?.length - 1) {
        setFormValues({
          ...formValues,
          subPopulations: formValues?.subPopulations?.map((sub, subIndex) =>
            subIndex === index ? { ...sub, value: newValue } : sub
          ),
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: formValues?.subPopulations,
        }));
      } else {
        let tempPopulation = [...formValues?.subPopulations];
        tempPopulation.push({ value: newValue });
        setFormValues({
          ...formValues,
          subPopulations: tempPopulation,
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: tempPopulation,
        }));
      }
    }
  };

  const handleSubPopulationTextChange = (index) => (event) => {
    let newSubPopulations = [...subPopulations];
    newSubPopulations[index].text = event.target.value;
    setSubPopulations(newSubPopulations);

    if (formValues?.subPopulations.length === 0) {
      let tempPopulation = [];
      tempPopulation.push({
        text: event.target.value,
        value: formValues.populationValue,
      });

      setChanges((prevChanges) => ({
        ...prevChanges,
        subPopulations: tempPopulation,
      }));
      setFormValues({
        ...formValues,
        subPopulations: tempPopulation,
      });
    } else {
      if (index <= formValues?.subPopulations?.length - 1) {
        setFormValues({
          ...formValues,
          subPopulations: formValues?.subPopulations?.map((sub, subIndex) =>
            subIndex === index ? { ...sub, text: event.target.value } : sub
          ),
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: formValues?.subPopulations,
        }));
      } else {
        let tempPopulation = [...formValues?.subPopulations];
        tempPopulation.push({
          text: event.target.value,
          value: formValues.populationValue,
        });
        setFormValues({
          ...formValues,
          subPopulations: tempPopulation,
        });
        setChanges((prevChanges) => ({
          ...prevChanges,
          subPopulations: tempPopulation,
        }));
      }
    }
  };

  const addSubSlider = () => {
    setSubPopulations([
      ...subPopulations,
      { value: [...populationValue], text: '' },
    ]);
  };

  const removeSubSlider = () => {
    // Create a new array that excludes the sub population at the given index
    const newSubPopulations = subPopulations.filter((_, i) => i !== toDelete);

    // Update both subPopulations and formValues.subPopulations
    setSubPopulations(newSubPopulations);

    setFormValues({
      ...formValues,
      subPopulations: formValues.subPopulations.filter(
        (_, i) => i !== toDelete
      ),
    });

    setChanges({
      ...changes,
      subPopulations: formValues.subPopulations.filter(
        (_, i) => i !== toDelete
      ),
    });

    // Reset the toDelete state and close the dialog
    setToDelete(null);
    setOpenDialog(false);
  };

  const openDeleteDialog = (index) => {
    // Set the toDelete state and open the dialog
    setToDelete(index);
    setOpenDialog(true);
  };

  const handleChange = (event, type) => {
    if (type) setSelectedField(type);
    let {
      target: { name, value },
    } = event;

    if (value === 'Yes, But...') {
      value = ['Yes, But...'];
      setLatestField(name);
      setOpen(true);
      // The below condition opens if Supervisor is updating supervisee
      if (
        isSelectedUserSupervisee ||
        (isSupervisee(currentUser) && formValues?.supervision?.initial_approval)
      ) {
        setAreaOfChange(name);

        // The below I am setting the previous and the current values
        setPreviousValue(formValues[name][0]);
        setCurrentValue(event.target.value);
      }
    } else {
      // The below condition opens if Supervisor is updating supervisee
      if (
        isSelectedUserSupervisee ||
        (isSupervisee(currentUser) && formValues?.supervision?.initial_approval)
      ) {
        setReasonDialogOpen(true); //opening the reasons dialog
        setAreaOfChange(name);

        // The below I am setting the previous and the current values
        setPreviousValue(formValues[name][0]);
        setCurrentValue(event.target.value);
      }
    }

    setFormValues({
      ...formValues,
      [name]: typeof value === 'string' ? value.split(',') : value,
    });

    let theValue = typeof value === 'string' ? value.split(',') : value;
    setChanges({ ...changes, [name]: theValue });
  };

  useEffect(() => {
    const effect = async () => {
      try {
        let providerConfigOptionResponse =
          await providerService.getTherapyConfigOptions();
        let providerConfigOption =
          providerConfigOptionResponse?.data?.providerConfigOption ?? {};
        setProviderConfigOption(providerConfigOption);
        let therapyConfig = await providerService.getTherapyConfig(
          props?.selectedUser?.id
        );
        const testingClone = {
          ...(therapyConfig?.data?.testing ?? defaultValues),
        };
        // fields added later on
        testingClone['testing_survey'] = testingClone['testing_survey'] || '';
        let localStorageFormValues = localStorage.getItem('formValuesTesting');
        if (localStorageFormValues) {
          let formValuesStored = JSON.parse(localStorageFormValues);

          if (formValuesStored[props?.selectedUser?.id]) {
            setFormValues({
              ...testingClone,
              ...formValuesStored[props.selectedUser.id],
            });
          } else {
            setFormValues(testingClone);
          }
        } else {
          setFormValues(testingClone);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    };
    showLoading();
    effect().finally(() => hideLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedUser?.id, triggerCallForSupervisee]);

  // The below function is used for finding the submitted Reason
  const findSubmittedReason = (value) => {
    for (let i = 0; i < supervisorReasons.length; i++) {
      const area = supervisorReasons[i].area.toLowerCase().trim();
      if (area === value.toLowerCase().trim()) {
        return `Reason- ${supervisorReasons[i].reason}`;
      }
    }
    return null; // If no match found
  };

  const handleSaveDraft = async (event) => {
    // confirm changes
    let changeJson = { ...changes, ...getChangesFromLocalStorage() };
    let r = await confirm({
      confirmation: confirmContent(changeJson, formValues),
      title: 'Summary Of Changes:',
    });
    if (!r) return;
    // user pressed yes
    addChangeNameValsToLocalStorage(changes);

    let formValuesStored = localStorage.getItem('formValuesTesting');
    if (formValuesStored) {
      formValuesStored = JSON.parse(formValuesStored);
      formValuesStored = {
        ...formValuesStored,
        [props.selectedUser.id]: formValues,
      };
      localStorage.setItem(
        'formValuesTesting',
        JSON.stringify(formValuesStored)
      );
    } else {
      localStorage.setItem(
        'formValuesTesting',
        JSON.stringify({ [props.selectedUser.id]: formValues })
      );
    }
    enqueueSnackbar('Draft saved successfully!', {
      variant: 'success',
      timeout: 3000,
    });
  };

  useEffect(() => {
    const keys = Object.keys(formValues);
    let emptyFields;
    if (isAdmin(currentUser)) {
      emptyFields = keys.find((obj) => {
        return (
          formValues[obj].length === 0 &&
          (obj === 'locations' ||
            obj === 'status' ||
            obj === 'insurances' ||
            obj === 'testing_survey')
        );
        // != 'therapy_issues' && obj != 'therapy_modalities' && obj != 'therapy_type' && obj != 'specialized_therapies' && obj != 'Deaf / Hard of Hearing' && obj != 'Under 18 1st Appt Policy' && obj != 'Working with Mental Health Professionals' && obj != 'Individuals' && obj != 'Couples' && obj != 'DBT' && obj != 'OCD' && obj != 'EMDR' && obj != 'Imago' && obj != 'Autism' && obj != 'Groups' && obj != 'Gestalt' && obj != 'Jungian' && obj != 'Somatic' && obj != 'Adlerian' && obj != 'Adoption' && obj != 'Coaching' && obj != 'Eclectic' && obj != 'Families' && obj != 'Feminist' && obj != 'Gambling' && obj != 'Addiction' && obj != 'Mediation' && obj != 'Narrative' && obj != 'Parenting' && obj != 'Sand Play' && obj != 'Humanistic' && obj != 'Relational' && obj != 'Sociopathy' && obj != "Alzheimer's" && obj != 'Art Therapy' && obj != 'Entitlement' && obj != 'Existential' && obj != 'Infertility' && obj != 'Life Coaching' && obj != 'Integrative'
      });
    } else if (isDoctor(currentUser)) {
      emptyFields = keys.find((obj) => {
        return (
          formValues[obj].length === 0 &&
          (providerConfigOption?.testing_areas_of_assessment?.includes(obj) ||
            providerConfigOption?.testing_forensics_issues?.includes(obj))
        );
        // != 'therapy_issues' && obj != 'therapy_modalities' && obj != 'therapy_type' && obj != 'specialized_therapies' && obj != 'Deaf / Hard of Hearing' && obj != 'Under 18 1st Appt Policy' && obj != 'Working with Mental Health Professionals' && obj != 'Individuals' && obj != 'Couples' && obj != 'DBT' && obj != 'OCD' && obj != 'EMDR' && obj != 'Imago' && obj != 'Autism' && obj != 'Groups' && obj != 'Gestalt' && obj != 'Jungian' && obj != 'Somatic' && obj != 'Adlerian' && obj != 'Adoption' && obj != 'Coaching' && obj != 'Eclectic' && obj != 'Families' && obj != 'Feminist' && obj != 'Gambling' && obj != 'Addiction' && obj != 'Mediation' && obj != 'Narrative' && obj != 'Parenting' && obj != 'Sand Play' && obj != 'Humanistic' && obj != 'Relational' && obj != 'Sociopathy' && obj != "Alzheimer's" && obj != 'Art Therapy' && obj != 'Entitlement' && obj != 'Existential' && obj != 'Infertility' && obj != 'Life Coaching' && obj != 'Integrative'
      });
    } else {
      emptyFields = keys.find((obj) => {
        return (
          formValues[obj].length === 0 &&
          testingConfigRequiredFields.includes(obj)
        );
        // != 'therapy_issues' && obj != 'therapy_modalities' && obj != 'therapy_type' && obj != 'specialized_therapies' && obj != 'Deaf / Hard of Hearing' && obj != 'Under 18 1st Appt Policy' && obj != 'Working with Mental Health Professionals' && obj != 'Individuals' && obj != 'Couples' && obj != 'DBT' && obj != 'OCD' && obj != 'EMDR' && obj != 'Imago' && obj != 'Autism' && obj != 'Groups' && obj != 'Gestalt' && obj != 'Jungian' && obj != 'Somatic' && obj != 'Adlerian' && obj != 'Adoption' && obj != 'Coaching' && obj != 'Eclectic' && obj != 'Families' && obj != 'Feminist' && obj != 'Gambling' && obj != 'Addiction' && obj != 'Mediation' && obj != 'Narrative' && obj != 'Parenting' && obj != 'Sand Play' && obj != 'Humanistic' && obj != 'Relational' && obj != 'Sociopathy' && obj != "Alzheimer's" && obj != 'Art Therapy' && obj != 'Entitlement' && obj != 'Existential' && obj != 'Infertility' && obj != 'Life Coaching' && obj != 'Integrative'
      });
    }
    if (emptyFields) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    const effect = async () => {
      try {
        let providerConfigOptionResponse =
          await providerService.getTherapyConfigOptions();
        let providerConfigOption =
          providerConfigOptionResponse?.data?.providerConfigOption ?? {};
        setProviderConfigOption(providerConfigOption);

        let therapyConfig = await providerService.getTherapyConfig(
          props?.selectedUser?.id
        );

        // Correctly merge defaultValues and data from the server
        const testingClone = {
          ...defaultValues,
          ...(therapyConfig?.data?.testing ?? {}),
        };

        // Ensure 'testing_survey' has a default value if missing
        testingClone['testing_survey'] = testingClone['testing_survey'] || '';

        let localStorageFormValues = localStorage.getItem('formValuesTesting');
        if (localStorageFormValues) {
          let formValuesStored = JSON.parse(localStorageFormValues);

          if (formValuesStored[props?.selectedUser?.id]) {
            setFormValues({
              ...testingClone,
              ...formValuesStored[props.selectedUser.id],
            });
          } else {
            setFormValues(testingClone);
          }
        } else {
          setFormValues(testingClone);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    };
    showLoading();
    effect().finally(() => hideLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedUser?.id, triggerCallForSupervisee]);

  useEffect(() => {
    if (formValues?.supervision?.initial_request) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 1,
      }));
    } else if (formValues?.supervision?.modification_request) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 3,
      }));
    } else if (formValues?.supervision?.modification_approval) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 4,
      }));
    } else if (formValues?.supervision?.initial_approval) {
      setReviewState((prevState) => ({
        ...prevState,
        statusIndex: 2,
      }));
    }
  }, [formValues]);

  useEffect(() => {
    setSubPopulations([...(formValues.subPopulations || [])]);
  }, [formValues]);

  useEffect(() => {
    if (props?.selectedUser?.id) {
      // passing both the currentUser.id and selectedUser.id
      UserService.isSelectedUserSupervisee(props?.selectedUser?.id).then(
        (response) => {
          setIsSelectedUserSupervisee(response.data);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedUser?.id]);

  const handleOnSubmit = async (event) => {
    try {
      let shouldSendSuperviseeEmail = false; // This boolean keeps track of sending supervisee the email

      if (props.selectedUser.id) {
        // TODO: uncomment this and implement proper required fields
        // const response = await providerService.updateTestingConfig(props.selectedUser.id, formValues)
        // enqueueSnackbar('Configuration saved successfully!', {
        //   type: 'success',
        //   timeout: 5000,
        // });
        // return;
        // confirm changes
        let changeJson = { ...changes, ...getChangesFromLocalStorage() };
        let r = await confirm({
          confirmation: confirmContent(changeJson, formValues),
          title: 'Submit Changes',
        });
        // user pressed yes
        if (!r) return;
        if (Object.keys(changeJson).length === 0) {
          return;
        }
        addChangeNameValsToLocalStorage(changes);

        // Prepare the data to submit based on the user role
        let dataToSubmit = {};
        if (isAdmin(currentUser)) {
          // Admin can submit all fields in the form
          dataToSubmit = { ...formValues };
        } else {
          // Provider-specific logic
          const keys = Object.keys(formValues);
          const emptyFields = keys.find((obj) => {
            return (
              formValues[obj].length === 0 &&
              testingConfigRequiredFields.includes(obj)
            );
          });

          // If there are empty fields, do not proceed
          if (emptyFields) {
            // Optionally, show an error message to the user
            return;
          }
          // Prepare all form values for submission
          dataToSubmit = { ...formValues };
        }

        // here I am now checking if the user who is submitting is supervisee and updating the supervisee object
        if (
          (isSupervisee(currentUser) ||
            props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
              'Non-Fully Licensed'
            )) &&
          !formValues.supervision.initial_approval
        ) {
          dataToSubmit.supervision.initial_request = true;
          dataToSubmit.supervision.date_of_initial_request = new Date();
        }

        // The below is the case that checks if the supervisee is requesting modification again  once initial approval was granted
        if (
          (isSupervisee(currentUser) ||
            props?.selectedUserDetailForProvider?.degreesAndLicense?.includes(
              'Non-Fully Licensed'
            )) &&
          formValues.supervision.initial_approval
        ) {
          dataToSubmit.supervision.modification_request = true;
          // Appending the modification history object again
          dataToSubmit.supervision.modifications_history = [
            ...formValues?.supervision?.modifications_history,
          ];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.modifications_history.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: false, // setting it false
            });
          }

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.latest_modifications.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: false, // setting it false
            });
          }
        }

        // The below condition checks if supervisor is filling supervisee and actually the revision was requested
        if (
          isSelectedUserSupervisee &&
          isOnlyDoctorOrSupervisor(currentUser) &&
          dataToSubmit.supervision.initial_request &&
          !dataToSubmit.supervision.initial_approval
        ) {
          dataToSubmit.supervision.supervision_initial_modifications = [];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.supervision_initial_modifications.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: true,
            });
          }

          dataToSubmit.supervision.date_of_initial_approval =
            new Date().toString();
          dataToSubmit.supervision.initial_approval = true;
          dataToSubmit.supervision.initial_request = false;
          shouldSendSuperviseeEmail = true;
        }

        // The below condition checks if supervisor is filling supervisee and actually modification was requested by the supervisee
        if (
          isSelectedUserSupervisee &&
          isOnlyDoctorOrSupervisor(currentUser) &&
          dataToSubmit?.supervision?.modification_request &&
          formValues?.supervision?.initial_approval
        ) {
          dataToSubmit.supervision.modifications_history = [
            ...formValues?.supervision?.modifications_history,
          ];

          for (let i = 0; i < supervisorReasons.length; i++) {
            dataToSubmit.supervision.modifications_history.push({
              ['area' + i]: supervisorReasons[i].area,
              reason: supervisorReasons[i].reason,
              previousValue: supervisorReasons[i].previousValue,
              currentValue: supervisorReasons[i].currentValue,
              date: supervisorReasons[i].date,
              isSelectedUserSupervisee: true,
            });
          }

          dataToSubmit.supervision.modification_request = false;
          dataToSubmit.supervision.modification_approval = true;
          dataToSubmit.supervision.latest_modifications = [];
          shouldSendSuperviseeEmail = true;
        }

        await providerService.updateTestingConfig(
          props.selectedUser.id,
          dataToSubmit
        );
        // create notifications for care coordinators
        await notificationService.createNotification({
          target_role_name: 'ROLE_CARE_COORDINATOR',
          notification_obj: {
            message: `User ${currentUser.username} updated ${props.selectedUser.username}'s Appropriateness configuration`,
            fields: changeJson,
          },
          target_user_id:
            props.selectedUser.id === currentUser.id
              ? null
              : props.selectedUser.id,
        });
        socket.emit('provider_config_updated', {
          user: currentUser,
          message: `User ${currentUser.username} updated ${props.selectedUser.username}'s Appropriateness configuration`,
          fields: changeJson,
          timestamp: new Date().getTime(),
        });

        enqueueSnackbar('Testing Configuration saved successfully!', {
          variant: 'success',
          timeout: 3000,
        });

        // The below condition checks if supervisor is filling supervisee and in that case an email needs to be send
        if (shouldSendSuperviseeEmail) {
          Object.keys(changeJson).forEach((key, index) => {
            supervisorReasons[index].value = changeJson[key][0];
          });

          await providerService.sendApprovalEmail({
            superviseeEmail: props.selectedUser.email,
            superviseeName: props.selectedUser.username,
            supervisorEmail: currentUser.email,
            supervisorReasons: supervisorReasons,
            timestamp: new Date().getTime(),
          });
        }

        let formValuesStored = localStorage.getItem('formValuesTesting');
        if (formValuesStored) {
          formValuesStored = JSON.parse(formValuesStored);
          delete formValuesStored[props?.selectedUser?.id];
          localStorage.setItem(
            'formValuesTesting',
            JSON.stringify(formValuesStored)
          );
        }
        purgeChangeFromLocalStorage(props.selectedUser?.id);
        setChanges({});
        setSupervisorReasons([]);
        // }

        // This condition doesn't refresh but for supervisee locks the screen
        setTriggerCallForSupervisee(
          (triggerCallForSupervisee) => !triggerCallForSupervisee
        );
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    }
  };

  const getTheReason = (key) => {
    const keyWords = key.split(' '); // Split the key into individual words

    for (let i = 0; i < supervisorReasons.length; i++) {
      const areaWords = supervisorReasons[i].area.split(' '); // Split the area into individual words
      // Check if any word from the key is included in the area words
      if (keyWords.some((word) => areaWords.includes(word))) {
        return supervisorReasons[i].reason;
      }
    }
    return '';
  };

  const confirmContent = (content) => {
    let keys = Object.keys(content);
    return (
      <div>
        {isSelectedUserSupervisee &&
          formValues?.supervision?.latest_modifications?.length > 0 && (
            <>
              {formValues.supervision.latest_modifications.map(
                (modification, i) => {
                  const areaKey = Object.keys(modification).find((key) =>
                    key.startsWith('area')
                  );

                  const areaValue = modification[areaKey];

                  // Check if the area is "populationValue"
                  if (areaValue === 'populationValue') {
                    // Split previousValue and currentValue into separate variables
                    const [prevValue1, prevValue2] = modification.previousValue;
                    const [curValue1, curValue2] = modification.currentValue;

                    return (
                      <React.Fragment key={i}>
                        Supervisee Requested - <b>{areaValue}</b> - from -{' '}
                        <b>
                          {prevValue1}, {prevValue2}
                        </b>{' '}
                        to{' '}
                        <b>
                          {curValue1}, {curValue2}
                        </b>
                        <br />
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={i}>
                        Supervisee Requested - <b>{areaValue}</b> - from -{' '}
                        <b>{modification.previousValue}</b> to{' '}
                        <b>{modification.currentValue}</b>
                        <br />
                      </React.Fragment>
                    );
                  }
                }
              )}
              <br />
            </>
          )}
        <p>
          {' '}
          The following is a list of the specific items you just changed. Please
          review all of the items to confirm that all of the changes are indeed
          accurate.
        </p>
        {keys.length > 0 ? (
          keys.map((key) => {
            return (
              <div key={key}>
                <li>
                  <b>{key}</b>:{JSON.stringify(content[key])}
                  {(isSelectedUserSupervisee ||
                    (isSupervisee(currentUser) &&
                      formValues?.supervision?.initial_approval)) && (
                    <span>
                      {' '}
                      <b>Reason</b>: {getTheReason(key)}
                    </span>
                  )}
                </li>
              </div>
            );
          })
        ) : (
          <h3>No Changes</h3>
        )}
        <br />
        <p>
          {!isSupervisee(currentUser) ? (
            <i>
              Notice. The changes that you have made will take effect
              immediately and directly impact the clients that you are assigned
              as this information is used by our care coordinators when they
              schedule new clients.
            </i>
          ) : (
            <i>
              As you are supervisee, so this will be submitted for a review..
            </i>
          )}
        </p>
        <p>
          <i>
            By clicking on the "Submit Changes" button below, you are
            acknowledging that you are aware of this. If you need to go back and
            edit your entries, please click on the " Back" button below.
          </i>
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.isProvider && !props.isSupervisee && (
        <Typography
          variant="body1"
          className={styles.providerNotice}
          sx={{ width: '100%' }}
        >
          There are some parts of the "Approp-Testing" section that are
          read-only. Please reach out to Levi or Victoria if there is any
          information in the read-only sections that you would like to update.
        </Typography>
      )}
      {props.isSupervisee && (
        <Typography
          variant="body1"
          className={styles.providerNotice}
          sx={{ width: '100%' }}
        >
          First few parts of the "Approp-Testing" section are read-only for
          supervisees. Please reach out to your supervisor if there is any
          information in the read-only sections that you would like to update
          and then reach out back to Levi or Victoria.
        </Typography>
      )}

      <div className={styles.tabsContentContainer}>
        <Box sx={{ width: '100%' }}>
          <div>
            <Accordion
              expanded={expanded === 'Locations'}
              onChange={handleChangeAccordion('Locations')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Locations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <FormControl
                    sx={{
                      m: 1, // applies margin
                      width: {
                        xs: '100%', // 100% width on extra-small screens
                        sm: '100%', // 100% width on small screens
                        md: '100%', // 80% width on medium screens
                        lg: '50%', // 50% width on large screens
                        xl: '40%', // 40% width on extra-large screens
                      },
                      maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                    }}
                    error={formValues['locations'].length > 0 ? false : true}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Locations
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      name="locations"
                      multiple
                      value={formValues['locations']}
                      onChange={(e) => {
                        handleChange(e, false);
                      }}
                      input={<OutlinedInput label="Locations" />}
                      renderValue={(selected) => {
                        // Conditionally show different amounts of selected items based on screen size
                        if (isSmallScreen) {
                          return selected.length > 1
                            ? `${selected[0]} + ${selected.length - 1} more...`
                            : selected.join(', ');
                        }
                        return selected.join(', '); // Shows all items as a joined string on larger screens
                      }}
                      MenuProps={MenuProps}
                      sx={{
                        width: '100%', // Ensures Select uses the full width of its container
                        maxWidth: '100%', // Prevents the Select from exceeding the width of its container
                      }}
                      disabled={props.isSupervisee || props.isProvider}
                    >
                      {providerConfigOption &&
                        providerConfigOption.locations &&
                        Array.isArray(providerConfigOption.locations) &&
                        providerConfigOption.locations.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues['locations'] &&
                                formValues['locations'].indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {formValues['locations'].length === 0 && (
                      <FormHelperText>Required field</FormHelperText>
                    )}
                  </FormControl>
                </>
              </AccordionDetails>
            </Accordion>

            {/* Testing Provider Status Accordion */}
            <Accordion
              expanded={expanded === 'Testing Provider Status'}
              onChange={handleChangeAccordion('Testing Provider Status')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Testing Provider Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <FormControl
                    sx={{
                      m: 1, // applies margin
                      width: {
                        xs: '100%', // 100% width on extra-small screens
                        sm: '100%', // 100% width on small screens
                        md: '100%', // 80% width on medium screens
                        lg: '50%', // 50% width on large screens
                        xl: '40%', // 40% width on extra-large screens
                      },
                      maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                    }}
                    error={formValues['status'].length > 0 ? false : true}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Testing Provider Status
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      name="status"
                      value={formValues['status']}
                      onChange={(e) => {
                        handleChange(e, false);
                      }}
                      input={<OutlinedInput label="Therapy Provider Status" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      sx={{
                        width: '100%', // Ensures Select uses the full width of its container
                        maxWidth: '100%', // Prevents the Select from exceeding the width of its container
                      }}
                      disabled={props.isSupervisee || props.isProvider}
                    >
                      {providerConfigOption &&
                        providerConfigOption.status &&
                        Array.isArray(providerConfigOption.status) &&
                        providerConfigOption.status.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues['status'] &&
                                formValues['status'].indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {formValues['status'].length === 0 && (
                      <FormHelperText>Required field</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    sx={{
                      m: 1, // applies margin
                      width: {
                        xs: '100%', // 100% width on extra-small screens
                        sm: '100%', // 100% width on small screens
                        md: '100%', // 80% width on medium screens
                        lg: '50%', // 50% width on large screens
                        xl: '40%', // 40% width on extra-large screens
                      },
                      maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                    }}
                    error={formValues['insurances'].length > 0 ? false : true}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Insurances
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-multiple-checkbox-label"
                      name="insurances"
                      value={formValues['insurances']}
                      onChange={(e) => {
                        handleChange(e, false);
                      }}
                      input={<OutlinedInput label="Insurances" />}
                      renderValue={(selected) => {
                        // Conditionally show different amounts of selected items based on screen size
                        if (isSmallScreen) {
                          return selected.length > 1
                            ? `${selected[0]} + ${selected.length - 1} more...`
                            : selected.join(', ');
                        }
                        return selected.join(', '); // Shows all items as a joined string on larger screens
                      }}
                      MenuProps={MenuProps}
                      sx={{
                        width: '100%', // Ensures Select uses the full width of its container
                        maxWidth: '100%', // Prevents the Select from exceeding the width of its container
                      }}
                      disabled={props.isSupervisee || props.isProvider}
                    >
                      {providerConfigOption &&
                        providerConfigOption.insurances &&
                        Array.isArray(providerConfigOption.insurances) &&
                        providerConfigOption.insurances.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues['insurances'] &&
                                formValues['insurances'].indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {formValues['insurances'].length === 0 && (
                      <FormHelperText>Required field</FormHelperText>
                    )}
                  </FormControl>
                </>
              </AccordionDetails>
            </Accordion>

            {/* Below case for displaying through NewUser */}
            {!props.displayingThroughNewuser && (
              <React.Fragment>
                <Accordion
                  expanded={expanded === 'Population'}
                  onChange={handleChangeAccordion('Population')}
                  sx={{
                    width: '100%', // Ensures the Accordion does not exceed the width of its container
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography>Population</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        width: '100%', // Ensures Box takes the full width of its container
                        p: 1, // Adds padding inside the Box
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Slider
                          aria-label="Population range"
                          value={formValues.populationValue}
                          onChange={handlePopulationChange}
                          valueLabelDisplay="on"
                          disabled={
                            (formValues?.supervision?.initial_request ||
                              formValues?.supervision?.modification_request) &&
                            isSupervisee(currentUser)
                          }
                        />
                      </ThemeProvider>

                      <Button
                        onClick={addSubSlider}
                        variant="contained"
                        color="primary"
                        disabled={
                          (formValues?.supervision?.initial_request ||
                            formValues?.supervision?.modification_request) &&
                          isSupervisee(currentUser)
                        } //
                      >
                        Add Age Group
                      </Button>
                      <Typography sx={{ marginTop: 2, fontStyle: 'italic' }}>
                        <span style={{ fontWeight: 'bold' }}>
                          {' '}
                          Adding an Age Group is optional. It allows you to
                          create specific Age Groups within your main Age Group.
                          For example a Sub-Population 10-15 can have a note
                          that says "Parents must attend 1st session alone
                          without client"{' '}
                        </span>
                      </Typography>
                    </Box>
                    {subPopulations.map((sub, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography>Age Group {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ width: '100%' }}>
                            <ThemeProvider theme={theme}>
                              <Slider
                                aria-label={`Age Group ${index + 1}`}
                                value={sub.value}
                                min={formValues.populationValue[0]}
                                max={formValues.populationValue[1]}
                                onChange={handleSubPopulationChange(index)}
                                valueLabelDisplay="on"
                                disabled={
                                  (formValues?.supervision?.initial_request ||
                                    formValues?.supervision
                                      ?.modification_request) &&
                                  isSupervisee(currentUser)
                                } //
                              />
                            </ThemeProvider>

                            <TextField
                              label={`Note for sub population ${index + 1}`}
                              variant="outlined"
                              value={sub.text}
                              required
                              fullWidth
                              onChange={handleSubPopulationTextChange(index)}
                            />

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 2, // Adds margin top
                              }}
                            >
                              <Button
                                onClick={() => openDeleteDialog(index)}
                                variant="contained"
                                color="secondary"
                              >
                                Remove this Age Group
                              </Button>
                              <Button
                                onClick={addSubSlider}
                                variant="contained"
                                color="primary"
                              >
                                Add Another Age Group
                              </Button>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Areas of Assessment'}
                  onChange={handleChangeAccordion('Areas of Assessment')}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Areas of Assessment</Typography>

                      <div style={{ flexGrow: 1 }}></div>
                      {/* The below condition make sures that it is being displayed to only supervisee*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.testing_areas_of_assessment &&
                        Array.isArray(
                          providerConfigOption.testing_areas_of_assessment
                        ) &&
                        providerConfigOption.testing_areas_of_assessment.map(
                          (therapyTypeObj) => {
                            return (
                              <FormControl
                                sx={{
                                  m: 1, // applies margin
                                  width: {
                                    xs: '100%', // 100% width on extra-small screens
                                    sm: '100%', // 100% width on small screens
                                    md: '100%', // 80% width on medium screens
                                    lg: '50%', // 50% width on large screens
                                    xl: '40%', // 40% width on extra-large screens
                                  },
                                  maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                                }}
                                error={
                                  formValues[therapyTypeObj]?.length > 0
                                    ? false
                                    : true
                                }
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {therapyTypeObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={therapyTypeObj}
                                  value={
                                    Array.isArray(formValues[therapyTypeObj])
                                      ? formValues[therapyTypeObj]
                                      : []
                                  }
                                  onChange={(e) => {
                                    handleChange(e, therapyTypeObj);
                                  }}
                                  input={
                                    <OutlinedInput label={therapyTypeObj} />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review and user loggedin is supervisee
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[therapyTypeObj] &&
                                          formValues[therapyTypeObj].indexOf(
                                            name
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {formValues[therapyTypeObj]?.length === 0 && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[`${therapyTypeObj}`] ===
                                    'Yes, But...' &&
                                    formValues[`${therapyTypeObj}_reason`]}
                                </div>

                                {/*Supervisor Reasons*/}
                                <div>
                                  {findSubmittedReason(`${therapyTypeObj}`)}
                                </div>

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${therapyTypeObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(`${therapyTypeObj}`)
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'Forensics Issues'}
                  onChange={handleChangeAccordion('Forensics Issues')}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Forensics Issues</Typography>
                      <div style={{ flexGrow: 1 }}></div>
                      {/* The below condition makes sure that it is being displayed to only supervisee, supervisor, Admin or carecoordinators*/}
                      {!loading &&
                        isSelectedUserSupervisee &&
                        (isSupervisorORSupervisee(currentUser) ||
                          isAdminOrCareCoordinator(currentUser)) && (
                          <StatusShowingComponentSupervisee
                            currentStatus={currentStatus}
                          />
                        )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {providerConfigOption &&
                        providerConfigOption.testing_forensics_issues &&
                        Array.isArray(
                          providerConfigOption.testing_forensics_issues
                        ) &&
                        providerConfigOption.testing_forensics_issues.map(
                          (specializedTherapiesObj) => {
                            return (
                              <FormControl
                                sx={{
                                  m: 1, // applies margin
                                  width: {
                                    xs: '100%', // 100% width on extra-small screens
                                    sm: '100%', // 100% width on small screens
                                    md: '100%', // 80% width on medium screens
                                    lg: '50%', // 50% width on large screens
                                    xl: '40%', // 40% width on extra-large screens
                                  },
                                  maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                                }}
                                error={
                                  formValues[specializedTherapiesObj]?.length >
                                  0
                                    ? false
                                    : true
                                }
                              >
                                <InputLabel id="demo-multiple-checkbox-label">
                                  {specializedTherapiesObj}
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  name={specializedTherapiesObj}
                                  value={formValues[specializedTherapiesObj]}
                                  onChange={(e) => {
                                    handleChange(e, specializedTherapiesObj);
                                  }}
                                  input={
                                    <OutlinedInput
                                      label={specializedTherapiesObj}
                                    />
                                  }
                                  renderValue={(selected) => {
                                    return selected.join(', ');
                                  }}
                                  MenuProps={MenuProps}
                                  disabled={
                                    (formValues?.supervision?.initial_request ||
                                      formValues?.supervision
                                        ?.modification_request) &&
                                    isSupervisee(currentUser)
                                  } // This prop disabled the field when submitted for initial review
                                >
                                  {commonOptions.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          formValues[specializedTherapiesObj] &&
                                          formValues[
                                            specializedTherapiesObj
                                          ].indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {formValues[specializedTherapiesObj]?.length ===
                                  0 && (
                                  <FormHelperText>
                                    Required field
                                  </FormHelperText>
                                )}
                                <div>
                                  {formValues[`${specializedTherapiesObj}`] ===
                                    'Yes, But...' &&
                                    formValues[
                                      `${specializedTherapiesObj}_reason`
                                    ]}
                                </div>

                                {/*Supervisor Reasons*/}
                                <div>
                                  {findSubmittedReason(
                                    `${specializedTherapiesObj}`
                                  )}
                                </div>

                                {/*The below component for showing history to selective users */}
                                <div>
                                  {!loading &&
                                    isSelectedUserSupervisee &&
                                    (isSupervisorORSupervisee(currentUser) ||
                                      isAdminOrCareCoordinator(
                                        currentUser
                                      )) && (
                                      <HistoryShowingComponentSuperivsee
                                        question={`${specializedTherapiesObj}`}
                                        formValues={formValues}
                                        setHistoryData={setHistoryData}
                                        handleOpenHistory={() =>
                                          handleOpenHistory(
                                            `${specializedTherapiesObj}`
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </FormControl>
                            );
                          }
                        )}
                    </>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            )}

            {!props.isProvider && (
              <Accordion
                expanded={expanded === 'Links'}
                onChange={handleChangeAccordion('Links')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Links</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <FormControl
                      sx={{
                        m: 1, // applies margin
                        width: {
                          xs: '100%', // 100% width on extra-small screens
                          sm: '100%', // 100% width on small screens
                          md: '100%', // 80% width on medium screens
                          lg: '50%', // 50% width on large screens
                          xl: '40%', // 40% width on extra-large screens
                        },
                        maxWidth: '100%', // Ensures the control never exceeds 100% of its container's width
                      }}
                      error={
                        formValues['testing_survey']?.length > 0 ? false : true
                      }
                    >
                      <TextField
                        autoComplete="off"
                        required
                        fullWidth
                        variant="outlined"
                        label="Testing Survey"
                        value={formValues['testing_survey']}
                        name="testing_survey"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.value,
                          });
                          setChanges({
                            ...changes,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        disabled={props.isProvider}
                      />
                      {formValues['testing_survey']?.length === 0 && (
                        <FormHelperText>Required field</FormHelperText>
                      )}
                    </FormControl>
                  </>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Box>
      </div>

      {!props.displayingThroughNewuser && (
        <React.Fragment>
          <Grid2 size={{ xs: 6 }} container spacing={2}>
            <Grid2 item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveDraft}
                sx={{ marginTop: 2 }}
              >
                Save Draft
              </Button>
            </Grid2>

            <Grid2 item>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !isAdmin(currentUser)
                    ? submitDisabled
                    : !areAdminFieldsComplete()
                }
                onClick={handleOnSubmit}
                sx={{ marginTop: 2 }}
              >
                {formValues?.supervision?.modification_request &&
                isSelectedUserSupervisee
                  ? 'Review Modifications'
                  : 'Submit'}
              </Button>
            </Grid2>
          </Grid2>
        </React.Fragment>
      )}

      {/* Here Testing Config yes,But.. Dialog */}
      <TestingConfigDialog
        open={open}
        handleClose={handleClose}
        setReason={setReason}
        reason={reason}
        setFormValues={setFormValues}
        selectedField={selectedField}
        formValues={formValues}
      />

      <SubPopulationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        removeSubSlider={removeSubSlider}
      />

      {/* Here is below the reason Dialog */}
      <TestingReasonDialog
        reasonDialogOpen={reasonDialogOpen}
        setReasonDialogOpen={setReasonDialogOpen}
        changeReason={changeReason}
        setChangeReason={setChangeReason}
        supervisorReasons={supervisorReasons}
        setSupervisorReasons={setSupervisorReasons}
        setAreaOfChange={setAreaOfChange}
        areaOfChange={areaOfChange}
        previousValue={previousValue}
        currentValue={currentValue}
        isSelectedUserSupervisee={isSelectedUserSupervisee}
      />
      {/* Integrated Dialog for History */}
      <TestingHistoryDialog
        historyDialogOpen={historyDialogOpen}
        handleCloseHistory={handleCloseHistory}
        historyData={historyData}
      />
    </React.Fragment>
  );
};

export default memo(TestingConfig);
