export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }
  /* eslint-disable no-useless-escape */
  var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(validRegex)) {
    return true;
  }
  return false;
};

export const isValidDate = (value) => {
  // Check if the input matches the format YYYY-MM-DD
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(value)) {
    // If the format is incorrect or incomplete, return false
    return false;
  }

  // Parse the date components to check if it's a valid date
  const [year, month, day] = value.split('-').map(Number);

  // Check that the year is at least 2001 and within a reasonable maximum (e.g., 9999)
  if (year < 2001 || year > 9999) {
    return false;
  }

  const date = new Date(year, month - 1, day);

  // Ensure the date matches the input (prevents invalid dates like 2024-02-30)
  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  );
};

export const removeNullUndefinedValues = (obj) => {
  const keys = Object.keys(obj);

  return keys.reduce((result, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

export const replaceIndexWithZero = (array) => {
  array[1] = array[0];

  // Remove the first element to ensure there are only three elements
  array.splice(0, 1);

  // Return the modified array
  return array;
};

export const getAbsoluteURL = (url) => {
  // Check if URL already contains a valid protocol
  if (/^https?:\/\//i.test(url)) {
    return url;
  }
  return `https://${url}`; // Assuming https if no protocol is specified
};
