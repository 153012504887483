import React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearFormData } from '../../actions/scheduling';

const TopBanner = ({ showBanner, setShowBanner }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  /**
   * The above two lines are there for responsiveness
   */

  return (
    <div
      style={{
        position: 'sticky',
        top: isMobile ? 58 : 48,
        zIndex: 100,
      }}
    >
      {showBanner && (
        <div>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'purple',
              padding: isMobile ? '0' : '10px 0',
              paddingY: { xs: 1, sm: 1.25 },
              paddingBottom: isMobile ? '20px' : '10px',
              paddingTop: isMobile ? '0px' : '10px',
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
                alignItems: { xs: 'stretch', sm: 'center' }, // Stretch items on mobile
                gap: { xs: 1, sm: 0 }, // Gap between items
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  flexGrow: 1,
                  textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile
                  fontSize: { xs: '16px', sm: '20px', md: '20px', lg: '20px' }, // Responsive font size
                  color: 'white',
                  mb: { xs: 1, sm: 0 }, // Margin bottom on mobile
                }}
              >
                Notice: This Module has fields that have been previously
                completed.
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row', // Keep buttons side by side
                  gap: 2, // Space between buttons
                  width: { xs: 'auto', sm: 'auto' }, // Adjust as needed
                  justifyContent: 'center', // Center buttons on mobile
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'white',
                    color: 'purple',
                    '&:hover': {
                      backgroundColor: '#f0f0f0', // Hover effect
                    },
                    flex: isMobile ? 1 : 'auto', // Take equal space on mobile
                  }}
                  onClick={() => {
                    setShowBanner(false);
                  }}
                >
                  Dismiss
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'white',
                    color: 'purple',
                    '&:hover': {
                      backgroundColor: '#f0f0f0', // Hover effect
                    },
                    flex: isMobile ? 1 : 'auto', // Take equal space on mobile
                  }}
                  onClick={async () => {
                    setShowBanner(false);
                    dispatch(clearFormData());
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay to clear the store
                    window.location.reload();
                  }}
                >
                  Clear Fields
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </div>
  );
};

export default TopBanner;
