import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid2,
} from '@mui/material';
import { RemoveRedEye, Create } from '@mui/icons-material';
import moment from 'moment';

const ReminderCard = ({
  row,
  handleEyeClick,
  handleOpenSettingModal,
  setSelectedReminder,
  setSelectedAppointmentId,
  setSelectedStartDate,
  setSelectedEndDate,
  setSelectedSlotId,
  setReason,
  setIsCancel,
  selectedMainFilter,
  getSurveySentStatus, // Passed as prop
  careCoordinators, // Passed as prop if needed
}) => {
  // Define colors based on email status
  let emailStatusColor = '';
  if (row?.email_status === 'pending') {
    emailStatusColor = '#fff9c4';
  } else if (row?.email_status === 'Sent') {
    emailStatusColor = 'lightgreen';
  } else if (row?.email_status === 'cancelled') {
    emailStatusColor = '#e85858';
  }

  // Color for Survey Sent Status
  let surveySentStatusColor = '';
  const surveySentStatus = getSurveySentStatus(row);
  if (surveySentStatus === 'Pending') {
    surveySentStatusColor = '#fff9c4';
  } else if (surveySentStatus === 'Sent') {
    surveySentStatusColor = 'lightgreen';
  } else if (surveySentStatus === 'Cancelled') {
    surveySentStatusColor = '#e85858';
  }

  // Helper function to capitalize first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Card
      style={{
        marginBottom: '16px',
        width: '95%',
        maxWidth: '600px',
        margin: 'auto',
        border: '1px solid #ccc', // Added border
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // Optional shadow
      }}
    >
      <CardContent>
        {/* First Name and Last Name centered and larger */}
        <Typography variant="h5" align="center" gutterBottom>
          {row?.first_name} {row?.last_name}
        </Typography>

        {/* Top Grid: Left - Email Status and Survey Sent; Right - Appt Date/Time */}
        <Grid2 container spacing={1} style={{ marginBottom: '8px' }}>
          {/* Left Side */}
          <Grid2 item xs={6}>
            <Typography
              variant="subtitle1"
              style={{
                backgroundColor: emailStatusColor,
                padding: '4px',
                borderRadius: '4px',
                marginBottom: '4px',
              }}
            >
              Email Status: {capitalizeFirstLetter(row?.email_status)}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                backgroundColor: surveySentStatusColor,
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              Survey Sent: {surveySentStatus}
            </Typography>
          </Grid2>
          {/* Right Side */}
          <Grid2 item xs={6}>
            <Typography variant="body2">
              <strong>Appt Date:</strong>{' '}
              {moment.utc(row?.appointment_start_time).format('MM/DD/yyyy') ??
                ''}
            </Typography>
            <Typography variant="body2">
              <strong>Appt Start Time:</strong>{' '}
              {moment.utc(row?.start_date_time).format('h:mm:ss a')}
            </Typography>
            <Typography variant="body2">
              <strong>Appt End Time:</strong>{' '}
              {moment.utc(row?.end_date_time).format('h:mm:ss a')}
            </Typography>
          </Grid2>
        </Grid2>

        {/* Other fields in two columns */}
        <Grid2 container spacing={1}>
          {/* Left Column */}
          <Grid2 item xs={6}>
            <Typography variant="body2">
              <strong>Provider:</strong> {row?.provider?.username ?? ''}
            </Typography>
            <Typography variant="body2">
              <strong>Date/Time Created:</strong>{' '}
              {moment(row?.createdAt).local().format('M/DD/yyyy h:mm:ss a')}
            </Typography>
            <Typography variant="body2">
              <strong>Care Coordinator:</strong>{' '}
              {careCoordinators.find(
                (coordinator) => coordinator.id === row?.care_coordinator_id
              )?.username ?? ''}
            </Typography>
            <Typography variant="body2">
              <strong>Email:</strong> {row?.email}{' '}
              {row?.cc_recipients?.email && row?.cc_recipients?.email !== ''
                ? `, ${row.cc_recipients.email}`
                : ''}
            </Typography>
            <Typography variant="body2">
              <strong>DOB:</strong>{' '}
              {moment(row?.date_of_birth ?? '').format('MM/DD/yyyy')}
            </Typography>
          </Grid2>

          {/* Right Column */}
          <Grid2 item xs={6}>
            <Typography variant="body2">
              <strong>Appt Type:</strong> {row?.appointment_type ?? ''}
            </Typography>
            <Typography variant="body2">
              <strong>Location:</strong> {row?.appointment_location}
            </Typography>
            <Typography variant="body2">
              <strong>Service Type:</strong> {row?.service_group ?? ''}
            </Typography>
            <Typography variant="body2">
              <strong>Issue:</strong>{' '}
              {Array.isArray(row.services)
                ? row.services.map((service) => service.title).join(', ')
                : 'No Services'}
            </Typography>
            <Typography variant="body2">
              <strong>Insurance:</strong> {row?.insurance}
            </Typography>
          </Grid2>
        </Grid2>

        {/* Action Icons at the bottom */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <IconButton onClick={(e) => handleEyeClick(e, row)}>
            <RemoveRedEye />
          </IconButton>
          {selectedMainFilter !== '2' && (
            <IconButton
              onClick={() => {
                setSelectedReminder(row);
                setSelectedAppointmentId(row.appointment_id);
                setSelectedStartDate(row.start_date_time);
                setSelectedEndDate(row.end_date_time);
                setSelectedSlotId(row.provider_availability_slots_id);
                setReason('');
                setIsCancel(false);
                handleOpenSettingModal();
              }}
            >
              <Create />
            </IconButton>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ReminderCard;
