import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Dark purple color
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFFFFF', // Ensure white text for selected tabs
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          flexWrap: 'nowrap',
          padding: '0.5rem',
          marginTop: '16px',
          '& .MuiButton-root': {
            height: '40px',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            flexGrow: 1,
            flexShrink: 1,
            minWidth: '130px', // Default min-width
            '@media (max-width: 960px)': {
              // md breakpoint
              minWidth: '110px',
            },
            '@media (max-width: 600px)': {
              // sm breakpoint
              minWidth: '90px',
            },
          },
        },
      },
    },
  },
});

export default theme;
