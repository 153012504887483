import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { createConfirmation } from 'react-confirm';
import { notificationService } from '../services';
import { setNotificationCount } from '../actions/notification';
import { clearLoading, setLoading } from '../actions/loading';
import { tsToLocalDtStr } from '../common/utils/DateUtils';
import { ConfirmDialog } from './Dialogs';

const Notifications = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [notifications, setNotifications] = useState([]);
  const [, setCount] = useState(0);
  const { notification } = useSelector((state) => state.notification);

  const confirm = createConfirmation(ConfirmDialog);

  const fetchNotifications = async () => {
    showLoading();
    try {
      const notificationsResp = await notificationService.getNotifications();
      const notificationsData = notificationsResp.data.data.notifications;

      // Sort notifications by `created_at` in descending order
      notificationsData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setNotifications(notificationsData);

      let read = 0;
      for (let i = 0; i < notificationsData.length; i++) {
        let not = notificationsData[i];
        if (not.read_at) {
          read++;
        }
      }
      setCount(read);
      dispatch(setNotificationCount(notificationsData.length - read));
    } catch (e) {
      console.log('error : ', e);
    } finally {
      hideLoading();
    }
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);
  const confirmContent = (notification) => {
    const keys = Object.keys(notification.fields || []);
    return (
      <div>
        <p>Hi, {currentUser.username}!</p>
        <br />
        <p>This is a notification that {notification.message}</p>
        <br />
        <p>
          <i>
            Note: There is no action you need to take. This is just a
            notification for you to be aware of this change.
          </i>
        </p>
        <br />
        <p>Summary of Changes</p>
        {keys.length > 0 ? (
          keys.map((key) => {
            return (
              <div key={key}>
                <li>
                  <b>{key}</b>:{JSON.stringify(notification.fields[key])}
                </li>
              </div>
            );
          })
        ) : (
          <h3>No Changes</h3>
        )}
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const extractProviderName = (message) => {
    if (!message) {
      return '';
    }
    const startIndex = message.indexOf('User ') + 'User '.length;
    const endIndex = message.indexOf(' updated');

    if (startIndex !== -1 && endIndex !== -1) {
      return message.substring(startIndex, endIndex);
    }

    return '';
  };

  const extractRelatedProvider = (message) => {
    if (!message) {
      return '';
    }
    const startIndex = message.indexOf('updated ') + 'updated '.length;
    const endIndex = message.indexOf("'s Appropriateness configuration");

    if (startIndex !== -1 && endIndex !== -1) {
      return message.substring(startIndex, endIndex);
    }

    return '';
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewNotification = async (id, notification_obj) => {
    await confirm({
      confirmation: confirmContent(notification_obj),
      title: 'Submit Changes',
    });
    // await fetchNotifications();

    let theNotification = notifications.find((it) => it.id === id);
    if (theNotification.read_at) {
      // already read. so return
      return;
    }
    theNotification.read_at = new Date();
    setNotifications(notifications);
    let read = 0;
    for (let i = 0; i < notifications.length; i++) {
      let not = notifications[i];
      if (not.read_at) {
        read++;
      }
    }
    setCount(read);
    dispatch(setNotificationCount(notifications.length - read));
    await notificationService.markRead(id);
  };

  const deleteNotification = async (id) => {
    await notificationService.deleteNotification(id);
    await fetchNotifications();
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  return (
    <div className="main-container" style={{ paddingLeft: '2.5rem' }}>
      {isMobile ? (
        notifications.map((notification, index) => (
          <Card
            key={notification.id}
            sx={{
              marginBottom: 2,
              backgroundColor: notification.read_at ? '#f5f5f5' : 'inherit', // Light grey background for read notifications
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: notification.read_at ? 'normal' : 'bold',
                }}
              >
                {notification.title}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontWeight: notification.read_at ? 'normal' : 'bold',
                }}
              >
                {tsToLocalDtStr(notification.created_at)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: notification.read_at ? 'normal' : 'bold',
                }}
              >
                {notification.message}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() =>
                  viewNotification(
                    notification.id,
                    notification.notification_obj
                  )
                }
              >
                View
              </Button>
              <Button
                size="small"
                onClick={() => deleteNotification(notification.id)}
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        ))
      ) : (
        <TableContainer
          component={Paper}
          sx={{ marginTop: '20px', maxHeight: 500 }}
        >
          {notifications.length === 0 && (
            <Box
              sx={{
                minHeight: 100,
                display: 'flex',
                justifyContent: 'center',
              }}
              alignItems="center"
            >
              No Notifications{' '}
            </Box>
          )}
          <>
            {notifications.length > 0 && (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Timestamp</TableCell>
                    <TableCell>Person That Made Update</TableCell>
                    <TableCell align="left">
                      Provider's Approp Config Changed
                    </TableCell>

                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notifications
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          backgroundColor: row.read_at ? '#f5f5f5' : 'inherit', // Light grey for read notifications
                        }}
                      >
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: row.read_at ? 'normal' : 'bold',
                            }}
                          >
                            {tsToLocalDtStr(row.created_at)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontWeight: row.read_at ? 'normal' : 'bold',
                            }}
                          >
                            {extractProviderName(row.notification_obj.message)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              fontWeight: row.read_at ? 'normal' : 'bold',
                            }}
                          >
                            {extractRelatedProvider(
                              row.notification_obj.message
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            onClick={() =>
                              viewNotification(row.id, row.notification_obj)
                            }
                          >
                            View
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => deleteNotification(row.id)}
                            sx={{ marginLeft: 2 }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </>
        </TableContainer>
      )}

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={notifications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Important Info</DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
          }}
        >
          <Typography>
            Content that fits within the dialog without requiring horizontal
            scrolling.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notifications;
