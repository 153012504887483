import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { TablePagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import { userService, providerService } from '../../services';
import ResponsiveTableCard from '../UI/ResponsiveTableCard';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';

function compareProviderFullName(a, b) {
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';
  return lastNameA.localeCompare(lastNameB);
}

const formatLink = (url) => {
  // Inserts a zero-width space character after ".me/"
  return url.replace(/(.me\/)/g, '$1\u200B');
};

const ProviderPracticeInfoTable = ({ showActiveProviders }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';
    showLoading();

    providerService
      .getProviders(status)
      .then((response) => {
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        return userService.getAllUsers().then((usersResponse) => {
          const users = usersResponse?.data?.users ?? [];

          const providersWithImages = fetchedProviders.map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          const fetchDetailsPromises = providersWithImages.map((provider) =>
            getProviderDetail(provider.id, provider)
              .then((detail) => ({ ...provider, ...detail }))
              .catch((error) => {
                console.error(
                  'Failed to fetch details for provider:',
                  provider.id,
                  error
                );
                return provider;
              })
          );

          Promise.all(fetchDetailsPromises).then((completedProviders) => {
            const validProviders = completedProviders.filter(
              (provider) => provider.provider_name != null
            );
            setLocations(validProviders);
            setProviders(validProviders);
          });
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showActiveProviders, enqueueSnackbar, showLoading, hideLoading]);

  const getProviderDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedProviders = useMemo(() => {
    return locations
      .sort(compareProviderFullName)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [locations, page, rowsPerPage]);

  const formattedProviders = useMemo(() => {
    return displayedProviders.map((provider) => {
      const formattedLocation = provider.location
        ? provider.location
            .split(',')
            .map((loc) => loc.trim())
            .join('\n')
        : '';

      const hipaaStatus =
        provider.hipaa != null
          ? Number(provider.hipaa) === 1
            ? 'True'
            : 'False'
          : '';

      const doxyLink = provider.static_doxy_link
        ? formatLink(provider.static_doxy_link)
        : 'Uses Google Meet';

      return {
        ...provider,
        location: formattedLocation,
        hipaa: hipaaStatus,
        static_doxy_link: doxyLink,
      };
    });
  }, [displayedProviders]);

  const columns = useMemo(() => {
    const commonColumns = [
      {
        field: 'provider_name',
        headerName: 'Full Name',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'location',
        headerName: 'Location',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'date_start',
        headerName: 'Start Date',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'admin_handling_name',
        headerName: 'Admin Handling Account',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'static_doxy_link',
        headerName: 'Doxy/Meet',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'hipaa',
        headerName: 'HIPAA Workforce',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'hrs_p_week',
        headerName: 'Hours Per Week',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'pw',
        headerName: 'Password',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'schedule',
        headerName: 'Schedule',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'llc_name',
        headerName: 'LLC Name',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'llc_address',
        headerName: 'LLC Address',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'sdat_num',
        headerName: 'SDAT Number',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
      {
        field: 'tax_id',
        headerName: 'Tax ID',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      },
    ];

    if (!showActiveProviders) {
      commonColumns.splice(3, 0, {
        field: 'date_end',
        headerName: 'End Date',
        headerStyle: {
          /* Your header styles */
        },
        cellStyle: {
          /* Your cell styles */
        },
      });
    }

    return commonColumns;
  }, [showActiveProviders]);

  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    fontSize: '1.0rem', // Increase font size
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };
  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <ResponsiveTableCard
        columns={columns}
        data={formattedProviders}
        isDesktopTableStyled={true}
        tableHeaderCellStyle={tableHeaderCellStyle}
        tableCellStyle={tableCellStyle}
      />
      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
};
export default memo(ProviderPracticeInfoTable);
