export const licensedInOtherStatesOptions = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const additionalLanguagesSpokenOptions = [
  'Arabic',
  'Cantonese',
  'French',
  'Hebrew',
  'Korean',
  'Mandarin',
  'Persian',
  'Spanish',
];

export const degreesAndLicenseOptions = [
  'Fully Licensed',
  'Non-Fully Licensed',
  'Master Level',
  'Doctoral Level',
];

export const getUpdatedLicenseEmailCases = (
  licenseEmailCases,
  changesMadeForEvents
) => {
  // Clone the licenseEmailCases object
  const licenseEmailCasesClone = { ...licenseEmailCases };

  // Check if Maryland is in changesMadeForEvents and is true
  if (changesMadeForEvents?.Maryland === true) {
    // Remove all keys starting with 'case'
    Object.keys(licenseEmailCasesClone).forEach((key) => {
      if (key.startsWith('case')) {
        delete licenseEmailCasesClone[key];
      }
    });
  }

  // Iterate through the other keys in changesMadeForEvents (excluding Maryland)
  Object.keys(changesMadeForEvents).forEach((eventKey) => {
    // Skip Maryland since we handled it separately
    if (eventKey === 'Maryland') return;

    // If changesMadeForEvents[eventKey] is true, we remove corresponding keys from licenseEmailCasesClone
    if (changesMadeForEvents[eventKey] === true) {
      Object.keys(licenseEmailCasesClone).forEach((key) => {
        // If the key in licenseEmailCasesClone starts with the eventKey (e.g., "AL" for "AL1", "AL2", etc.)
        if (key.startsWith(eventKey)) {
          delete licenseEmailCasesClone[key];
        }
      });
    }
  });

  return licenseEmailCasesClone;
};
