export const getFirstCharacters = (str) => {
  const words = str?.split(' ');

  if (words?.length >= 2) {
    const firstCharacters = words?.map((word) => word[0]);

    return firstCharacters?.slice(0, 2)?.join('');
  } else {
    return '';
  }
};

export const parseDockHealthPatientDetails = (details) => {
  const keyValuePairs = details.split('\n');
  let parsedMessage = {};

  keyValuePairs.forEach((pair) => {
    let separatorIndex = pair.indexOf(':');
    let key = pair.slice(0, separatorIndex).trim();
    let value = pair.slice(separatorIndex + 1).trim();

    if (separatorIndex === -1) {
      const parts = pair.split(':');
      key = parts[0].trim();
      value = parts.slice(1).join(':').trim();
    }

    switch (key.toLowerCase()) {
      case 'first name':
      case 'name':
        const names = value.split(' ');
        parsedMessage['first_name'] = names[0];
        parsedMessage['last_name'] = names.slice(1).join(' ');
        break;
      case 'last name':
        parsedMessage['last_name'] = value;
        break;
      case 'dob':
        parsedMessage['date_of_birth'] = value;
        break;
      case 'phone number':
      case 'phone':
        const phoneNumber = value.replace(/[^0-9]/g, '');
        parsedMessage['phone'] = phoneNumber;
        break;
      case 'email':
        parsedMessage['email'] = value;
        break;
      case 'issue':
      case 'description for seeking help':
      case 'reason for seeking services':
        parsedMessage['issue'] = value;
        break;

      default:
        parsedMessage[key] = value;
        break;
    }
  });

  return parsedMessage;
};

export const extractEmailAddress = (inputString) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

  const match = inputString?.match(emailRegex);

  if (match) {
    return match[0];
  }

  return inputString;
};
