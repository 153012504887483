import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ResponsiveTabs = ({
  tabs,
  activeTab,
  onChange,
  useUniqueValues = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    onChange(event, newValue);
  };

  const handleSelectChange = (event) => {
    onChange(event, event.target.value);
  };

  // If the screen size is small, render a dropdown instead of tabs
  // Filter tabs based on their condition
  const visibleTabs = tabs.filter((tab) =>
    tab.condition === undefined ? true : tab.condition
  );
  if (isSmallScreen) {
    return (
      <Box
        sx={{
          minWidth: 120,
          maxWidth: '100%',
          marginBottom: 2,
          justifyContent: 'center',
        }}
      >
        <FormControl fullWidth variant="outlined">
          <Select
            labelId="responsive-tab-select-label"
            id="responsive-tab-select"
            sx={{
              width: '100%', // Adjust width as necessary
              maxHeight: 100,
              justifyContent: 'center',
              border: '3px solid #0033cc', // Dark blue border, thicker at 3px
              borderRadius: '4px', // Optional: if you want rounded corners
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Hide the default border
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#0033cc', // Dark blue border on hover, 3px to match normal state
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#0033cc', // Keep dark blue border when focused
                borderWidth: '3px', // Consistent thickness with normal state
              },
            }}
            value={activeTab}
            onChange={handleSelectChange}
            label="Select Tab"
          >
            {visibleTabs.map((tab) => (
              <MenuItem
                key={useUniqueValues ? tab.value : tab.index}
                value={useUniqueValues ? tab.value : tab.index}
                sx={{
                  justifyContent: 'center', // Center the menu item text
                  fontSize: '1rem', // Increase font size
                  padding: '10px 20px', // Adjust padding to reduce height
                  marginTop: 3,
                }}
              >
                {' '}
                {/* Apply font size here */}
                {tab.label || tab.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: '-40px', // Negative margin to move tabs higher
        marginBottom: '25px',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="responsive tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth" // Changed from "scrollable" to "fullWidth"
        TabIndicatorProps={{
          style: {
            height: '4px', // Optional: Increase the height of the indicator
          },
        }}
        sx={{
          minHeight: 'unset', // Remove default minHeight to adjust tab height
        }}
      >
        {visibleTabs.map((tab, index) => (
          <Tab
            key={useUniqueValues ? tab.value : tab.index}
            label={tab.label || tab.title}
            value={useUniqueValues ? tab.value : tab.index}
            sx={{
              flex: 1,
              maxWidth: 'none',
              minWidth: 'auto',
              backgroundColor:
                activeTab === (useUniqueValues ? tab.value : tab.index)
                  ? '#1c405c' // Blue background for selected tab
                  : '#f5f5f5', // Default background for unselected tabs
              color:
                activeTab === (useUniqueValues ? tab.value : tab.index)
                  ? '#FFFFFF' // Always bright white text for selected tab
                  : '#1c405c', // Blue text for unselected tabs
              fontWeight:
                activeTab === (useUniqueValues ? tab.value : tab.index)
                  ? 'bold'
                  : 'normal', // Bold text for active tab
              '&:hover': {
                backgroundColor:
                  activeTab === (useUniqueValues ? tab.value : tab.index)
                    ? '#1c405c' // Maintain the same blue background if selected
                    : '#e3e1e1', // Light gray on hover if unselected
                color:
                  activeTab === (useUniqueValues ? tab.value : tab.index)
                    ? '#FFFFFF' // No change for selected tab on hover
                    : '#1c405c', // Change text color for unselected tabs on hover
              },
              transition: 'background-color 0.3s', // Smooth background transition
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

ResponsiveTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired, // Unique identifier for the tab
      title: PropTypes.string.isRequired, // Title for display in Tabs and Select
      label: PropTypes.node, // Optional JSX for more complex labels
      condition: PropTypes.bool, // Optional condition to display the tab
    })
  ).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  useUniqueValues: PropTypes.bool, // Determines whether to use 'value' or 'index' as identifiers
};

ResponsiveTabs.defaultProps = {
  useUniqueValues: false,
};

export default ResponsiveTabs;
