export const providerAlertMapping = [
  { providerId: '20', alert: 'Alert message for Provider A' },
  {
    providerId: 'Danielle Rooney, Psy.D.',
    alert:
      'Please Make Sure  Client Knows This Provider is Leaving PsychCare in Mid July',
  },
  // Add more providers as needed
];

export const yesNoOptions = ['', 'No', 'Yes'];

export const durations = {
  15: '15 min',
  30: '30 min',
  45: '45 min',
  60: '1 hr',
  75: '1 hr 15 min',
  90: '1 hr 30 min',
  105: '1 hr 45 min',
  120: '2 hr',
  135: '2 hr 15 min',
  150: '2 hr 30 min',
  165: '2 hr 45 min',
  180: '3 hr',
  195: '3 hr 15 min',
  210: '3 hr 30 min',
  225: '3 hr 45 min',
  240: '4 hr',
  255: '4 hr 15 min',
  270: '4 hr 30 min',
  285: '4 hr 45 min',
  300: '5 hr',
  315: '5 hr 15 min',
  330: '5 hr 30 min',
  345: '5 hr 45 min',
  360: '6 hr',
};

export const entryTypeFormValuesValid = (entryTypeFormValues) => {
  let {
    entryType,
    existingClientOptions,
    returningClientQ1,
    cancellationOptions,
  } = entryTypeFormValues;

  if (entryType === 'new') {
    return true;
  }

  if (entryType === 'existing') {
    if (existingClientOptions === 'Transfer') {
      return true;
    }

    if (existingClientOptions === 'Testing Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Therapy Client Coming to Office') {
      return true;
    }

    if (existingClientOptions === 'Turning 18 years-Old') {
      return true;
    }
  }

  if (entryType === 'returning') {
    if (returningClientQ1) {
      return true;
    }
  }

  if (entryType === 'cancellation') {
    if (cancellationOptions) {
      return true;
    }
  }

  // Check if the entry type is 'waitlist' and return true if so
  if (entryType === 'waitlist') {
    return true;
  }

  return false;
};

export const cases = (entryTypeFormValues, services) => {
  return {
    case1:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer',

    // since transferQuestion is eliminated, case2 never fires
    case2:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Transfer' &&
      entryTypeFormValues.transferQuestion === 'After July 2021',

    // note that covid addendum question is now removed from therapy client coming to office option. so the case conditions have been changed. TODO: revisit case 3,4,5,6

    case3:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        // entryTypeFormValues.covidAddendum === 'Yes' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case4:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        services?.includes('Bariatric Surgical Psychological Evaluations')),

    // Since we removed the Covid Addendum - case 5 below will not be fired and commented out first part of case 5 since it's redundant with case 4
    case5:
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No' &&
      services?.includes('Bariatric Surgical Psychological Evaluations'),

    // since we removed the COVID Addendum case 6 will not be fired
    case6:
      (entryTypeFormValues.entryType === 'existing' &&
        entryTypeFormValues.existingClientOptions ===
          'Therapy Client Coming to Office') ||
      (entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
        entryTypeFormValues.covidAddendum === 'No' &&
        !services?.includes('Bariatric Surgical Psychological Evaluations')),

    case7:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,
    case8:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'Yes' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,
    case9:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') === -1,

    // case 10 never fires since returningClientQ2 is eliminated
    case10:
      entryTypeFormValues.entryType === 'returning' &&
      entryTypeFormValues.returningClientQ1 === 'No' &&
      entryTypeFormValues.returningClientQ2?.indexOf('Nota') > -1,

    case11:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions === 'Turning 18 years-Old',

    // two cases below will not be fired since we removed COVID Addendum

    caseCovidAddendumYes:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'Yes',

    caseCovidAddendumNo:
      entryTypeFormValues.entryType === 'existing' &&
      entryTypeFormValues.existingClientOptions ===
        'Testing Client Coming to Office' &&
      entryTypeFormValues.covidAddendum === 'No',
  };
};
