import React from 'react';
import PieCharts from '../../../components/UI/PieCharts';
import { Paper, Typography } from '@mui/material';
import BarCharts from '../../../components/UI/BarChart';
import StackBarCharts from '../../../components/UI/StackBarChat';

function NonConversionTab(props) {
  const {
    data,
    secondData,
    CustomTooltipForReferredOut,
    isMobile,
    reasonOptions,
    reasonColor,
  } = props;

  let matchLabel = {
    no_available_time: {
      reason: [
        'No Availability',
        'Time - No Availability',
        'No Available Time',
        'Declined Times Available',
        'Non-Converted - No Available Times',
      ],
      name: 'No Available Times',
    },
    insurance: {
      reason: ['Insurance - Related', 'Insurance', 'Non-Converted - Insurance'],
      name: 'Insurance',
    },
    office_only_request: {
      reason: ['In-Office Only Request (COVID)', 'In-Office Only Request'],
      name: 'In-Office Only Request',
    },
    declined_times: {
      reason: [
        'Time - Declined Times Offered',
        'Non-Converted - Declined Times Available',
      ],
      name: 'Declined Times Available',
    },
    appropriate_for_vs: {
      reason: [
        'Not Appropriate for VS',
        'Non-Converted - Non Appropriate for VS',
      ],
      name: 'Not Appropriate for VS',
    },
    speciality: {
      reason: [
        'Speciality - Not Offering',
        'Non-Converted - Currently Not Offering Speciality',
      ],
      name: 'No Speciality',
    },
    no_contact: {
      reason: ['Non-Converted - No Contact'],
      name: 'No Contact',
    },
    cost: {
      reason: ['Non-Converted - Cost', 'Cost'],
      name: 'Cost',
    },
    other: {
      reason: ['Other'],
      name: 'other',
    },
  };
  let groupingData = (flag, type) => {
    const result = [];
    let addDataIndexes = [];
    // eslint-disable-next-line
    secondData?.generic
      ?.filter((v, k) => v.service_group === flag)
      ?.forEach((item, index) => {
        Object.keys(matchLabel).forEach((key) => {
          if (matchLabel[key].reason.includes(item.reason || item.name)) {
            const existing = result.find(
              (r) => r.name === matchLabel[key].name,
            );
            if (existing) {
              existing.value += parseInt(item.value);
              existing[`label${existing.totalObj + 1}`] =
                item?.name + ' - ' + item?.alias;
              existing[`value${existing.totalObj + 1}`] = parseInt(item.value);
              existing.totalObj = existing.totalObj + 1;
            } else {
              result.push({
                name: matchLabel[key].name,
                label1: item?.name + ' - ' + item?.reason,
                value1: parseInt(item.value),
                value: parseInt(item.value),
                totalObj: 1,
              });
            }
            addDataIndexes.push(index);
          }
        });
      });
    // eslint-disable-next-line
    secondData?.generic
      ?.filter((v, k) => v.service_group === flag)
      ?.forEach((item, index) => {
        if (!addDataIndexes?.includes(index)) {
          let name = item?.name;
          result.push({
            ...item,
            name: item?.reason || item?.name,
            reason: name,
          });
        }
      });
    return result
      ?.filter((v, k) => {
        if (type === 'other') {
          return v?.name?.toLowerCase()?.includes('other');
        } else {
          return !v?.name?.toLowerCase()?.includes('other');
        }
      })
      ?.sort((a, b) => b.value - a.value);
  };
  return (
    <React.Fragment>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <div
          style={{
            overflowX: 'auto',
            paddingBottom: '50px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            center
            sx={{
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Therapy
          </Typography>

          {window.innerWidth >= 768 ? (
            <BarCharts isResponsive={false} data={groupingData('Therapy')} />
          ) : (
            <div style={{ height: '400px' }}>
              <PieCharts
                data={
                  secondData?.generic
                    ?.filter((v, k) => v.service_group === 'Therapy')
                    ?.map((v, k) => ({
                      ...v,
                      name: `${v?.reason} (${v?.name})`,
                      value: parseInt(v?.value),
                    })) || []
                }
              />
            </div>
          )}
        </div>
      </Paper>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <div
          style={{
            overflowX: 'auto',
            paddingBottom: '50px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            center
            sx={{
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Therapy (Others)
          </Typography>
          <BarCharts
            isResponsive={false}
            data={groupingData('Therapy', 'other')}
          />
        </div>
      </Paper>

      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <div
          style={{
            height: '500px',
            overflowX: 'auto',
            paddingBottom: '50px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            center
            sx={{
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Referred Out Therapy
          </Typography>
          <StackBarCharts
            CustomTooltip={
              <CustomTooltipForReferredOut reasonOptions={reasonOptions} />
            }
            data={secondData?.referred_out_threpy}
            showXaxis={!isMobile}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: !isMobile ? 'center' : '',
          }}
        >
          {reasonOptions?.map((v, k) => (
            <div style={{ display: 'flex' }} key={k}>
              <div
                style={{
                  textTransform: 'capitalize',
                }}
              >
                {v}
              </div>
              <div
                style={{
                  backgroundColor: reasonColor[k],
                  height: '10px',
                  marginTop: '7px',
                  width: '20px',
                  marginLeft: '10px',
                  marginRight: '20px',
                }}
              ></div>
            </div>
          ))}
        </div>
      </Paper>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <div
          style={{
            height: '500px',
            overflowX: 'auto',
            paddingBottom: '50px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            center
            sx={{
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Testing
          </Typography>

          {window.innerWidth >= 768 ? (
            <BarCharts isResponsive={false} data={groupingData('Testing')} />
          ) : (
            <div style={{ height: '400px' }}>
              <PieCharts
                data={
                  secondData?.generic
                    ?.filter((v, k) => v.service_group === 'Therapy')
                    ?.map((v, k) => ({
                      ...v,
                      name: `${v?.reason} (${v?.name})`,
                      value: parseInt(v?.value),
                    })) || []
                }
              />
            </div>
          )}
        </div>
      </Paper>
      <Paper
        style={{
          width:
            data?.length > 6
              ? ((data?.length || 0) * 200)?.toString()?.concat('px')
              : '100%',
        }}
      >
        <div
          style={{
            overflowX: 'auto',
            paddingBottom: '50px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            center
            sx={{
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Testing (Others)
          </Typography>
          <BarCharts
            isResponsive={false}
            data={groupingData('Testing', 'other')}
          />
        </div>
      </Paper>
      <div
        style={{
          height: '500px',
          overflowX: 'auto',
          paddingBottom: '50px',
          width: '100%',
          overflowY: 'hidden',
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          center
          sx={{ marginTop: '10px', marginLeft: '5px' }}
        >
          Referred Out Testing
        </Typography>
        <StackBarCharts
          // yAxisLabel={<LabelList dataKey="reason" position="top" />}
          CustomTooltip={
            <CustomTooltipForReferredOut reasonOptions={reasonOptions} />
          }
          isTick={window.innerWidth >= 768}
          data={secondData?.referred_out_testing}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: !isMobile ? 'center' : '',
        }}
      >
        {reasonOptions?.map((v, k) => (
          <div style={{ display: 'flex' }} key={k}>
            <div
              style={{
                textTransform: 'capitalize',
              }}
            >
              {v}
            </div>
            <div
              style={{
                backgroundColor: reasonColor[k],
                height: '10px',
                marginTop: '7px',
                width: '20px',
                marginLeft: '10px',
                marginRight: '20px',
              }}
            ></div>
          </div>
        ))}
      </div>
      <div style={{ height: '500px' }}>
        <PieCharts data={data || []} />
      </div>
    </React.Fragment>
  );
}

export default NonConversionTab;
